export const slideGoRight=(divEl)=>{
    const itemWidth = divEl.current.getElementsByTagName('div')[0].clientWidth
    divEl.current.scrollLeft += itemWidth
}

export const slideGoLeft=(divEl)=>{
    const itemWidth = divEl.current.getElementsByTagName('div')[0].clientWidth
    divEl.current.scrollLeft -= itemWidth
}

export const scrollToId=(id)=>{
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
}