import {proxy} from "valtio";
import {exhibitDataType, museumDataType} from "../types/dataType";

export const cardState = proxy<{
    fadeExhibit:exhibitDataType|undefined;
    fadeMuseum:museumDataType|undefined;
    selectedExhibit:exhibitDataType|undefined;
    selectedMuseum:museumDataType|undefined
}>({
    fadeExhibit:undefined,
    fadeMuseum:undefined,
    selectedExhibit:undefined,
    selectedMuseum:undefined,
})