import React, {useContext, useEffect, useState} from 'react';
import CrossIcon from "./icons/Cross";
import SearchCtx from "../store/SearchCtx";
import {motion} from "framer-motion";

const RecentlySearched:React.FC<{
    key?:string;
    className?:string;
    value?:string;
    canTouch:boolean;
}> = (props) => {
    const {className,value,canTouch} = props
    const {searchEnter,deleteSearchHistory,updateSearchKeyword} = useContext(SearchCtx)
    const [wouldDelete,setWouldDelete]=useState(false)

    useEffect(()=>{
        if(wouldDelete&&value){
            setTimeout(()=>{
                deleteSearchHistory(value)
            },500)
        }
    },[wouldDelete])

    return <motion.div
        animate={canTouch?{pointerEvents:'auto'}:{pointerEvents:'none'}}
        className={`flex px-2 py-1 items-center gap-1 bg-white rounded-full text-wine-400 pointer-events-auto cursor-pointer transition-opacity ${wouldDelete&&'opacity-0'}`}
    >
        <div className={"text-sm"} onClick={()=>{
            updateSearchKeyword(value||'')
            searchEnter(value)
        }}>{value}</div>
        <div className={"w-4"} onClick={()=>{
            setWouldDelete(true)
        }}><CrossIcon/></div>
    </motion.div>
}

export default RecentlySearched