import React, {useContext, useEffect, useRef, useState} from 'react';
import SearchCtx from "../../store/SearchCtx";
import {AnimatePresence,motion,AnimateSharedLayout} from "framer-motion";
import LanguageCtx from "../../store/LanguageCtx";
import IntroCard from "./IntroCard";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {cardState} from "../../store/ValtioState";


const MuseumIntro:React.FC<{
    key?:string;
    value:any;
}> = ({value}) => {
    const {selectedMuseum} = useSnapshot(cardState) as typeof cardState

    const [isOpen,setIsOpen] =useState(false)

    const navigate = useNavigate()
    const clickHandler=()=>{
        // updateSelectedMuseum(value)
        navigate(`museums/${value.id}`)
        // navigate(`museums/${value.name}`)
    }

    useEffect(()=>{
        if(selectedMuseum && selectedMuseum.name===value.name){
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }
    },[selectedMuseum])

    return <IntroCard
        title={value.name}
        picSrc={value.mainMedia}
        hoverPicSrc={value.hoverMedia}
        tagList={value.tagsList}
        onClick={clickHandler}
        isSelected={isOpen}
        idHook={`museum${value.id}`}
    />
}

export default MuseumIntro
