import SearchIcon from "../icons/SearchIcon";
import React, {useContext, useEffect, useRef, useState} from "react";
import RecentlySearched from "../RecentlySearched";
import {AnimatePresence, motion, useMotionValue} from "framer-motion";
import SearchCtx from "../../store/SearchCtx";
import LanguageCtx from "../../store/LanguageCtx";
import {GrClose} from "react-icons/gr";
import CloseButton from "../icons/CloseButton";
import CrossIcon from "../icons/Cross";

const SearchBar:React.FC<{className?:string}>=({className})=>{
    const { searchHistory, searchEnter,searchBarRef,searchKeyword, updateSearchKeyword, changeSearchPage} = useContext(SearchCtx)
    const { uiText }=useContext(LanguageCtx)

    const recentlySearchedRef = useRef<HTMLDivElement>(null)
    const [currentHeight,setCurrentHeight]=useState(48)
    const [isFocus,setIsFocus]=useState(false)
    const [hasData, setHasData] = useState((searchHistory.length>0))

    const reviseCurrentHeight=()=>{setCurrentHeight(recentlySearchedRef.current!.clientHeight||48)}

    useEffect(()=>{
        reviseCurrentHeight()
        if(searchHistory.length>0){
            setHasData(true)
        }else{
            setIsFocus(false)
            setHasData(false)
        }
    },[searchHistory])

    useEffect(()=>{
        reviseCurrentHeight()
    },[])

    const trans = {transition: { duration: 0.5 }}
    const transDelay = {transition: { duration: 0.5 ,delay:0.5}}
    const initStatus = {height:0}
    const animateStatus = {height:currentHeight}

    return <div className={`w-screen flex flex-col items-center m-4 `}>
        <div className={"z-[51] w-[70%] max-w-[720px] md:w-[50%] h-12 md:h-16 bg-wine-400 rounded-full text-white flex items-center py-4 px-4 md:px-8 gap-4"}
             ref={searchBarRef}
        >
            <div className={"w-6 md:w-8 -scale-x-100 flex-shrink-0"}>
                <SearchIcon/>
            </div>
            <input type="text" className={"outline-none bg-transparent flex-1 text-xl peer pointer-events-auto cursor-pointer"}
                   onFocus={()=>{
                       setIsFocus(true)
                       changeSearchPage(true)
                       reviseCurrentHeight()
                   }}
                   value={searchKeyword}
                   onChange={(e)=>{updateSearchKeyword(e.target.value)}}
                   onKeyDown={(e)=>{
                       if(e.key==='Enter'){
                           searchEnter()
                           setTimeout(()=>{reviseCurrentHeight()},300)

                       }
                   }}
            />
            {searchKeyword.length>0&&<div className="text-white w-6 h-6 cursor-pointer"
                                          onClick={()=>{
                                              updateSearchKeyword('')
                                              searchEnter()
                                          }}
            >
                <CrossIcon/>
            </div>}


        </div>

        <div className={`w-[70%] md:w-[50%] absolute pointer-events-none z-50 flex justify-center`}>
            <motion.div
                className={`h-0 rounded-b-[24px] md:rounded-b-[32px] w-full bg-wine-400/70 transition-all duration-500 mt-6 md:mt-8 max-w-[720px]`}
                initial={initStatus}
                animate={hasData&&isFocus?animateStatus:initStatus}
            >
            </motion.div>
        </div>
        <motion.div className={`z-50 w-[70%] md:w-[50%] mt-6 md:mt-8 absolute font-serif text-left pt-6 pb-4 px-8 md:pt-8 pointer-events-none max-w-[720px]`} ref={recentlySearchedRef}
             initial={{opacity:0}}
             animate={isFocus?{opacity:1,pointerEvents:"auto",...transDelay}:{opacity:0,...trans}}
        >
            <AnimatePresence>
                {hasData && <motion.div
                    initial={{opacity:0}}
                    exit={{opacity:0,pointerEvents:"none",...transDelay}}
                    animate={isFocus?{opacity:1,pointerEvents:"auto",...transDelay}:{opacity:0,...trans}}
                >
                    <div className={"text-white my-2"}>{uiText.search.mostRecent}</div>
                    <div className={"flex gap-4 flex-wrap"}>
                        {searchHistory.map(el => <RecentlySearched value={el} key={`RecentlySearched${el}`} canTouch={isFocus}/>)}
                    </div>
                </motion.div>}
            </AnimatePresence>
        </motion.div>
        {isFocus&&<div className={"z-10 fixed w-screen h-screen top-0"} onClick={()=>{setIsFocus(false)}}></div>}
    </div>
}

export default SearchBar