import { useEffect, useRef } from 'react';

// sm:640px, md:768px, lg:1024px, xl:1280px, 2xl:1536px

const useOverflowDetector = (widthIsLess, heightNotToExceed, handler) => {
    const targetRef = useRef(null)

    useEffect(() => {
        const handleResize = () => {
            const breakpoint = window.innerWidth < widthIsLess
            if (breakpoint && targetRef.current.offsetHeight > heightNotToExceed) {
                handler()
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return targetRef
}

export default useOverflowDetector

// function App() {
//     const copyrightRef = useOverflowDetector()
//
//     return (
//         <div className="App">
//             <div className="copyright" ref={copyrightRef}>
//                 Copyright Information
//             </div>
//         </div>
//     );
// }