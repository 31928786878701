import { useState, useEffect } from 'react'

const useLocalStorage = (key, initialValue) => {
    // 初始化時，先從 localStorage 取得數值，若沒有就用傳入的預設值
    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item !== null ? JSON.parse(item) : initialValue
        } catch (error) {
            console.log(error)
            return initialValue
        }
    })

    // 當 state 被改變時，同步更新 localStorage 中的值
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            console.log(error)
        }
    }, [key, value])

    return [value, setValue]
}

export default useLocalStorage