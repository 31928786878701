import { useState, useEffect } from 'react';

const useWindowSize = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        let timeoutID

        const handleResize = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        const debouncedHandleResize = () => {
            clearTimeout(timeoutID)
            timeoutID = setTimeout(handleResize, 250)
        }

        window.addEventListener('resize', debouncedHandleResize)

        // 下一次 useEffect 觸發前，會先執行這個函數以清除副作用
        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        };
    }, [])

    return {width,height}
}

export default useWindowSize

// const Example = () => {
//     const {width, height} = useWindowWidth()
//
//     return (
//         <div>
//             Screen Width: {width}
//         </div>
//     );
// }