import React, {useContext, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion, useMotionValue, useSpring, useTransform} from "framer-motion";
import searchCtx from "../../store/SearchCtx";
import {scrollToId, slideGoLeft, slideGoRight} from "../../utils/slide";
import BannerButton from "./BannerButton";
import {exhibitDataType, mediaData} from "../../types/dataType";
import {useInView} from "react-cool-inview";
import CarouselImg from "./CarouselImg";
import RightArrow from "../icons/RightArrow";
import LeftArrow from "../icons/LeftArrow";


const isLargeThenMd=()=>document.body.clientWidth>1024
// const isLargeThenMd=()=>document.body.clientWidth>768

const Carousel:React.FC<{
    exhibitData:exhibitDataType;
    layoutName: string;
}>=({exhibitData,layoutName})=>{

    // exhibitMedia 展覽頁上方的 banner
    // interactiveMedia 手手圖，有的話才需要做效果

    const bannerRef=useRef<HTMLDivElement>(null)
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const moveX = useTransform(x, [0, bannerRef.current?bannerRef!.current.clientWidth:1000], [20,-20])
    const moveY = useTransform(y, [0, bannerRef.current?bannerRef!.current.clientHeight:1000], [15,-15])
    const moveYSmall = useTransform(y, [0, bannerRef.current?bannerRef!.current.clientHeight:1000], [10,-10])
    const [canMove,setCanMove]=useState(false)
    const handX = useTransform(x, [0, bannerRef.current?bannerRef!.current.clientWidth:1000], [8,-8])
    const handRotate = useTransform(x, [0, bannerRef.current?bannerRef!.current.clientWidth:1000], [-20,20])
    const handRotateReverse = useTransform(x, [0, bannerRef.current?bannerRef!.current.clientWidth:1000], [20,-20])

    let lastImgName = ''

    const [autoScrollRight,setAutoScrollRight] = useState(true)
    const [showImgName,setShowImgName]=useState('')
    const changeShowImageName=(a:string)=>{setShowImgName(a)}
    const { observe, inView } = useInView({
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
    })

    const [canMoveTimeout1,setCanMoveTimeout1]=useState(false)
    const [canMoveTimeout2,setCanMoveTimeout2]=useState(false)
    let moveTimer1 = setTimeout(()=>{})
    let moveTimer2 = setTimeout(()=>{})
    useEffect(()=>{
        clearTimeout(moveTimer1)
        clearTimeout(moveTimer2)
        if(canMove){
            moveTimer1 = setTimeout(()=>{setCanMoveTimeout1(true)},300)
            moveTimer2 = setTimeout(()=>{setCanMoveTimeout2(true)},400)
        }else{
            setCanMoveTimeout1(false)
            setCanMoveTimeout2(false)
        }
        return ()=>{
            clearTimeout(moveTimer1)
            clearTimeout(moveTimer2)
        }
    },[canMove])

    // useEffect(()=>{
    //     if(inView){
    //         setShowImgName('handBanner')
    //     }
    // },[inView])

    // 自動scroll
    useEffect(() => {
        const intervalId = setInterval(() => {
            if(bannerRef&&bannerRef.current&&bannerRef.current.scrollLeft===0){
                bannerGo('right')
            }else{
                bannerGo('left')
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);


    // useEffect(()=>{
    //     if(showImgName===lastImgName && autoScrollRight){
    //         setAutoScroll(false)
    //         setTimeout(()=>{
    //             if(bannerRef&&bannerRef.current) {
    //                 bannerRef.current.scrollLeft = 0
    //             }
    //         },5000)
    // }},[showImgName])

    const imgNameList = exhibitData.exhibitMediaList.map(el=>el.name)

    const bannerGo=(type:string)=>{
        if(bannerRef&&bannerRef.current){
            const itemWidth = bannerRef.current.getElementsByTagName('div')[0].clientWidth
            if(type==='right'){
                // setScrollLeft(bannerRef.current.scrollLeft + itemWidth)
                bannerRef.current.scrollLeft = bannerRef.current.scrollLeft + itemWidth
                setShowImgName(prev=>imgNameList[imgNameList.indexOf(prev)+1])
            }else if(type==='left'){
                // setScrollLeft(bannerRef.current.scrollLeft - itemWidth)
                bannerRef.current.scrollLeft = bannerRef.current.scrollLeft - itemWidth
                setShowImgName(prev=>imgNameList[imgNameList.indexOf(prev)-1])
            }
        }
    }



    return <>
        {/*被移除的貓咪手手版本*/}
        {/*<motion.div className={`carousel w-full max-h-[50vh] ${exhibitData.hasInteractive&&'[&>*:nth-child(2)]:lg:hidden'}`} ref={bannerRef}*/}

        {/*>*/}
        {/*    {exhibitData.hasInteractive && <div id="handBanner" className="hidden lg:carousel-item w-full relative h-[30vh] lg:h-[45vh] cursor-pointer ">*/}
        {/*        <motion.img*/}
        {/*            layoutId={`${layoutName}${exhibitData.exhibitName}Image`}*/}
        {/*            className={`w-full object-cover z-10 `}*/}
        {/*            // src={exhibitData.exhibitMediaList[0].url} // 原版本的貓手 Banner*/}
        {/*            src={exhibitData.interactPhoto}*/}
        {/*            onMouseMove={(event)=>{*/}
        {/*                x.set(event.pageX)*/}
        {/*                y.set(event.pageY)*/}
        {/*            }}*/}
        {/*            onMouseOver={()=>{isLargeThenMd()&&setCanMove(true)}}*/}
        {/*            onMouseOut={()=>{setCanMove(false)}}*/}
        {/*            whileHover={canMoveTimeout1?{scale:1.1,filter:"blur(4px)"}:{scale:1}}*/}
        {/*            style={canMoveTimeout2?{x: moveX,y: moveY}:{}}*/}
        {/*            ref={observe}*/}
        {/*            onClick={()=>{window.open(exhibitData.exhibitURL)}}*/}
        {/*        />*/}
        {/*        <div className={"hidden md:flex flex-col absolute z-30 w-full pointer-events-none items-center overflow-hidden h-full"}>*/}
        {/*            <motion.div className="absolute handMask pointer-events-none w-full h-full"*/}
        {/*                        initial={{opacity:0}}*/}
        {/*                        animate={canMove?{opacity:1}:{opacity:0}}*/}
        {/*                        transition={{ delay: 0.03 }}*/}
        {/*                        style={canMove?{x: handX,rotate:handRotate,*/}
        {/*                            originY: 1,originX: 0.5,translateY:20*/}
        {/*                         }:{*/}
        {/*                             translateY:20*/}
        {/*                         }}*/}
        {/*            >*/}
        {/*                <motion.img*/}
        {/*                    className={`h-full object-cover z-10`}*/}
        {/*                    // src={exhibitData.exhibitMediaList[0].url} // 原版本的貓手 Banner*/}
        {/*                    src={exhibitData.interactPhoto}*/}
        {/*                    style={canMove?{rotate:handRotateReverse, x: moveX,y: moveYSmall,translateY:-10}:{translateY:-10,y: moveYSmall}}*/}
        {/*                />*/}
        {/*            </motion.div>*/}

        {/*            <motion.img src={exhibitData.catList[0]}*/}
        {/*                        style={canMove?{x: handX,rotate:handRotate,originY: 1,originX: 0.5,scale:1.1, translateY:60}:{scale:1.1, translateY:60}} //src={exhibitData.interactiveMediaList[0].url}*/}
        {/*                        transition={{ duration: 0.3 }}*/}
        {/*                        initial={{opacity:0}}*/}
        {/*                        animate={canMove?{opacity:1}:{opacity:0}}*/}
        {/*                        className="h-[40vh]"*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*    </div>}*/}
        {/*    {exhibitData.exhibitMediaList.length>0 && exhibitData.exhibitMediaList.map((el:mediaData)=>{*/}
        {/*        return <CarouselImg key={el.name} value={el} changeShowImageName={changeShowImageName}/>*/}
        {/*    })}*/}
        {/*</motion.div>*/}
        {/*<div className={`hidden md:flex justify-end items-center w-full p-2 gap-2 ${exhibitData.hasInteractive&&'[&>*:nth-child(2)]:lg:hidden'}`}>*/}
        {/*    {exhibitData.hasInteractive && <BannerButton targetId='handBanner' changeShowImageName={changeShowImageName} isActive={showImgName==='handBanner'}/>}*/}
        {/*    {exhibitData.exhibitMediaList.length>0 && exhibitData.exhibitMediaList.map((el:mediaData)=>{*/}
        {/*        lastImgName = el.name*/}
        {/*        return <BannerButton targetId={el.name} key={`BannerButton${el.name}`} changeShowImageName={changeShowImageName} isActive={showImgName===el.name}/>*/}
        {/*    })}*/}
        {/*</div>*/}

        {/*完全沒有貓咪手手的版本*/}
        <div className="relative">
            <div className="absolute w-full z-20 hidden md:flex justify-between items-center h-full">
                <div className={`group h-full bg-gradient-to-l from-black/0 to-black/50 w-20 text-white flex justify-center items-center ${(showImgName===exhibitData.exhibitMediaList.map(el=>el.name)[0])?'opacity-0 pointer-events-none':'opacity-100 cursor-pointer'}`}
                     onClick={()=>{bannerGo('left')}}
                >
                    <div className="h-10 w-8 group-hover:scale-125 transition-transform"><LeftArrow/></div>
                </div>
                <div className={`group h-full bg-gradient-to-r from-black/0 to-black/50 w-20 text-white flex justify-center items-center ${(showImgName===exhibitData.exhibitMediaList.map(el=>el.name)[exhibitData.exhibitMediaList.length - 1])?'opacity-0 pointer-events-none':'opacity-100 cursor-pointer'}`}
                     onClick={()=>{bannerGo('right')}}
                >
                    <div className="h-10 w-8 group-hover:scale-125 transition-transform"><RightArrow/></div>
                </div>
            </div>
            <motion.div className={`carousel w-full max-h-[50vh] `} ref={bannerRef}>
                {/*<div ref={observe}></div>*/}
                {exhibitData.exhibitMediaList.length>0 && exhibitData.exhibitMediaList.map((el:mediaData)=>{
                    return <CarouselImg key={el.name} value={el} changeShowImageName={changeShowImageName}/>
                })}
            </motion.div>
        </div>
        <div className={`hidden md:flex justify-end items-center w-full p-2 gap-2 `}>
            {exhibitData.exhibitMediaList.length>0 && exhibitData.exhibitMediaList.map((el:mediaData)=>{
                lastImgName = el.name
                return <BannerButton targetId={el.name} key={`BannerButton${el.name}`} changeShowImageName={changeShowImageName} isActive={showImgName===el.name}/>
            })}
        </div>
    </>
}
export default Carousel