import React, {useEffect, useState} from "react";
import {HashRouter  as Router, Route, Routes, useNavigate} from "react-router-dom";
import App from "../App";
import IntroPage, {isIntroPageOpenAtom} from "../components/Intro/IntroPage";
import {useAtom} from "jotai/index";

const ProjectRouter=()=>{
    const [isIntroPageOpen,setIsIntroPageOpen] = useAtom(isIntroPageOpenAtom)

    return <>
        <Router>
            <Routes>
                <Route path='/' element={<>
                    <div className={`transition-opacity duration-1000 ${!isIntroPageOpen?'opacity-100':'opacity-0'}`}>
                        <App />
                    </div>
                    <IntroPage/>
                </>}>
                    <Route path='about' element={<></>}/>
                    <Route path='museums/:museumId' element={<></>}>
                        <Route path=':exhibitId2' element={<></>}/>
                    </Route>
                    <Route path='exhibits/:exhibitId' element={<></>}>
                        <Route path=':museumId2' element={<></>}/>
                    </Route>
                    <Route path='not' element={<></>}/>
                </Route>
            </Routes>
        </Router>

    </>


}
export default ProjectRouter