import React, {useContext, useEffect, useRef, useState} from 'react';
import SortTagItem from "./SortTagItem";
import ExhibitIntro from "./ExhibitIntro";
import searchCtx from "../../store/SearchCtx";
import {motion, AnimatePresence} from "framer-motion";
import LeftArrow from "../icons/LeftArrow";
import RightArrow from "../icons/RightArrow";
import MuseumCard from "./MuseumCard";
import {museumDataType} from "../../types/dataType";
import LanguageCtx from "../../store/LanguageCtx";
import ResultTypeItem from "./ResultTypeItem";
import MuseumIntro from "./MuseumIntro";
import CategoryTags from "./CategoryTags";

const ResultSection:React.FC<{
    key?:string;
}> = (props) => {

    const { exhibits, museums, isResultShowExhibit,
        checkResultExhibit, showSearchPage, changeSearchPage,categoriesTags }=useContext(searchCtx)
    const { uiText }=useContext(LanguageCtx)

    const turnSearchProps = showSearchPage?{}:{
        onClick:()=>{changeSearchPage(true)}
    }

    // return <AnimatePresence>{showSearchPage&&(
    return <>
        <motion.section
            className={`w-full flex flex-col items-center mb-20 transition-opacity duration-500 ${!showSearchPage&&'opacity-40 pointer-events-none select-none blur-sm'}`}
        >
            <div className="w-[90%] lg:w-[80%] flex">
                <ResultTypeItem value={`${uiText.result.typeList[0]}(${exhibits.length.toString()})`} isActive={isResultShowExhibit} onClick={()=>{checkResultExhibit(true)}}/>
                <ResultTypeItem value={`${uiText.result.typeList[1]}(${museums.length.toString()})`} isActive={!isResultShowExhibit} onClick={()=>{checkResultExhibit(false)}}/>
            </div>
            <div className={`w-[90%] lg:w-[80%] bg-mystic-500 rounded-3xl ${isResultShowExhibit?'rounded-tl-none':'rounded-tr-none'}`}>
                {/*<div className={"flex flex-col-reverse lg:flex-row w-full h-full px-4 py-8 lg:divide-x-2 divide-[#adcada]"}>*/}
                <div className={"w-full h-full flex flex-col w-full h-full md:px-4 py-8"}>
                    {isResultShowExhibit && <CategoryTags tagList={categoriesTags}/>}
                    {isResultShowExhibit && <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
                        {exhibits.length > 0 && exhibits.map(el => <ExhibitIntro key={el.exhibitName} value={el}/>)}
                        {exhibits.length === 0 && <>
                            <div
                                className={"text-xl text-wine-400 w-[100rem] text-left pl-10"}>{uiText.result.noData}</div>
                        </>}
                    </div>}

                    {!isResultShowExhibit && <div className={"grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 "}>
                        {museums.length > 0 && museums.map((el: museumDataType) => <MuseumIntro key={el.name} value={el}/>)}
                        {museums.length === 0 && <>
                            <div
                                className={"text-xl text-wine-400 w-[100rem] text-left pl-10"}>{uiText.result.noData}</div>
                        </>}
                    </div>}
                </div>
            </div>
        </motion.section>
    </>
    // )}</AnimatePresence>
}

export default ResultSection
