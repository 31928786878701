import {FC, useContext, useEffect, useRef, useState} from "react";
import {useAtom,atom} from "jotai";
import {AnimatePresence,motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import LanguageCtx from "../store/LanguageCtx";
import ReactMarkdown from "react-markdown";

export const isOpenAboutPageAtom = atom(false)

const About:FC=()=>{
    const [isOpenAboutPage,setIsOpenAboutPage] = useAtom(isOpenAboutPageAtom)
    const navigate = useNavigate()
    const {uiText} = useContext(LanguageCtx)

    const aboutButtonRef = useRef(null);
    const [isHoveredAboutButton, setIsHoveredAboutButton] = useState(false);

    const reportButtonRef = useRef(null);
    const [isHoveredReportButton, setIsHoveredReportButton] = useState(false);

    useEffect(()=>{
        if(isOpenAboutPage){
            navigate('about')
        }else{
            navigate('/')
        }
    },[isOpenAboutPage])

    return <AnimatePresence>
        {isOpenAboutPage&&<motion.div
            className="overflow-y-scroll fixed top-16 left-0 w-screen h-[calc(100vh-64px)] bg-white z-[55] flex flex-col items-center pb-24"
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity:0}}
        >
            <div className="w-80 my-8">
                <img className="" src="./images/about/logo.png" />
            </div>
            <div className="mb-8 font-bold text-wine-500">{uiText.about.subTitle}</div>
            <div className="w-[90%] lg:w-[80%] bg-mystic-500 rounded-3xl py-8 md:py-16 px-12 md:px-20 flex flex-col items-center">
                <div className="ReactMarkdownAbout mb-8 text-wine-500 text-left leading-7 text-wine-500">
                    <ReactMarkdown>
                        {uiText.about.content}
                    </ReactMarkdown>
                </div>
                <div className="max-w-[900px] w-full relative -translate-y-8 flex flex-col items-center">
                    <div className="absolute w-full">
                        <img className="w-40 md:w-64 object-contain" src="./images/about/window.png"/>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center pt-12 md:pt-20 xl:pt-12">
                        <a className="w-56 h-56 sm:w-40 sm:h-40 md:w-56 md:h-56 lg:w-80 lg:h-80 rounded-xl bg-[#bed1cf] relative cursor-pointer"
                           href="https://docs.google.com/forms/d/e/1FAIpQLSf_NVoR1SDqNkBO01NOmVm2UN6Eg-uE0ZCZmsThXZvO_bA9dQ/viewform"
                           target="_blank"
                           ref={reportButtonRef}
                           onMouseEnter={()=>{setIsHoveredReportButton(true)}}
                           onMouseLeave={()=>{setIsHoveredReportButton(false)}}
                        >
                            <TextButtonContainer text={uiText.about.leaveUsAMessage} isHover={isHoveredReportButton}/>
                            <motion.img
                                className="object-cover" src="./images/about/reportpic.png"
                                animate={isHoveredReportButton?{rotateZ:[0,-5,5,0]}:{rotateZ:0}}
                                transition={{duration:0.8}}
                            />
                        </a>
                        <a className="w-56 h-56 sm:w-40 sm:h-40 md:w-56 md:h-56 lg:w-80 lg:h-80 rounded-xl bg-[#c4dbe1] relative cursor-pointer"
                           href="https://www.panosensing.com.tw/"
                           target="_blank"
                           ref={aboutButtonRef}
                           onMouseEnter={()=>{setIsHoveredAboutButton(true)}}
                           onMouseLeave={()=>{setIsHoveredAboutButton(false)}}
                        >
                            <TextButtonContainer text={uiText.about.getToKnowUsBetter} isHover={isHoveredAboutButton}/>
                            <motion.div className="absolute object-cover -mt-4 w-full"><CameraClipSvg isHover={isHoveredAboutButton}/></motion.div>
                            <motion.img
                                className="object-cover -mt-4" src="./images/about/camera.png"
                            />
                        </a>
                    </div>
                </div>

            </div>
            <div className="fixed right-8 bottom-0 pointer-events-none w-screen h-screen flex justify-end items-end">
                <div className="w-48 lg:w-64">
                    <img className={`object-contain ${isHoveredAboutButton?'hidden':'block'}`} src="./images/about/pig1.png" />
                    <img className={`object-contain ${isHoveredAboutButton?'block':'hidden'}`} src="./images/about/pig2.png" />
                </div>
            </div>

        </motion.div>}
    </AnimatePresence>
}
export default About

const TextButtonContainer:FC<{text:string;isHover:boolean}>=({text,isHover})=>{
    return <div className={`z-10 pointer-events-none absolute bottom-4 rounded-full bg-[#e5eaee] mx-[12.5%] w-3/4 h-1/4 ${isHover&&'shadow-xl'} flex justify-center items-center`}>
        <div className="text-wine-500 font-medium text-xl md:text-2xl lg:text-3xl">{text}</div>
    </div>
}

const CameraClipSvg:FC<{isHover:boolean}>=({isHover})=>{
    const lightAni = isHover?{
        animate:{opacity: 1, pathLength: 1,},
        transition:{delay:0.1,duration:0.2,ease: "easeInOut"}
    }:{
        animate:{opacity: 0, pathLength: 0,},
        transition:{duration:0.2,ease: "easeInOut"}
    }
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.98 388.48">
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="87.91" y1="42.96" x2="87.91" y2="42.05"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="87.91" y1="65.09" x2="87.91" y2="49.59"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="68.02" y1="73.75" x2="47.16" y2="52.89"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="60.08" y1="93.93" x2="49.7" y2="93.93"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="62.59" y1="83.77" x2="47.62" y2="77.59"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="41.21" y1="74.34" x2="40.43" y2="73.97"/>
        <motion.line {...lightAni} className="cls-1" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5px" x1="77.45" y1="69.59" x2="64.93" y2="42.88"/>
        <motion.path className="cls-2" fill="#fff"
                     animate={isHover?{scale:1}:{scale:0}}
                     transition={{duration:0.2}}
              d="M98,80h0a32.33,32.33,0,0,0,22.19,22.19h0a1.45,1.45,0,0,1,0,2.78h0A32.35,32.35,0,0,0,98,127.13h0a1.45,1.45,0,0,1-2.78,0h0a32.36,32.36,0,0,0-22.2-22.2h0a1.45,1.45,0,0,1,0-2.78h0A32.35,32.35,0,0,0,95.26,80h0A1.45,1.45,0,0,1,98,80Z"/>
    </svg>
}