import React, {FC} from 'react';

const ResultTypeItem:FC<{
    key?: string;
    value: any;
    isActive: boolean;
    onClick: ()=>void;
}> = (props) => {
    const {value, isActive, onClick} = props

    return <div className={`h-12 w-1/2 flex items-center justify-center rounded-t-3xl cursor-pointer px-4 hover:text-wine-400 ${isActive ? 'bg-mystic-500' : 'bg-white'}`}
                onClick={onClick}
    >
        <span className="tracking-[0.25em] leading-7">{value}</span>
    </div>
}

export default ResultTypeItem