let scrollTimer=setTimeout(()=>{})
const scrollToId=(id,time=0)=>{
    const targetElement = document.getElementById(id)
    if(targetElement){
        if(time===0){
            targetElement.scrollIntoView({behavior: 'smooth'})
        }else{
            clearTimeout(scrollTimer)
            scrollTimer = setTimeout(()=>{targetElement.scrollIntoView({behavior: 'smooth'})},time)
        }
    }
}
export default scrollToId