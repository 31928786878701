import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {AnimatePresence, AnimateSharedLayout, motion, useMotionValue, useTransform} from "framer-motion";
import {museumDataType} from "../../types/dataType";
import searchCtx from "../../store/SearchCtx";
import MuseumRightArrow from "../icons/MuseumRightArrow";
import {
    fadeProps,
    houseTagsContainer,
    houseTagsItem,
    scrollArrowProps,
    scrollTextProps
} from "../../utils/animationType";
import {useInView} from "react-cool-inview";
import ScrollArrow from "../icons/ScrollArrow";
import {useNavigate} from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import {staggeredLengthArray} from "../../utils/arrayControl";

//v6

enum busModeType {
    INITIALIZE,
    WAITING_TO_START,
    STARTING,
    STOPPING,
    SELECTING,
    FINISH
}

const BusNavigation: FC = () => {
    const {
        updateSearchKeyword,
        topPointHandler,
        originalMuseums,
        showSearchPage,
        changeSearchCategories,
        changeSearchPage,
        searchEnter,
    } = useContext(searchCtx)
    const navigate = useNavigate()
    
    const {width:windowWidth,height:windowHeight} = useWindowSize()

    const [busMode, setBusMode] = useState<busModeType>(busModeType.WAITING_TO_START)
    const [isFirstTime,setIsFirstTime]=useState(true)

    const [canSelect, setCanSelect] = useState(false)
    const [canControl, setCanControl] = useState(false)
    const moveBusX = useMotionValue(0)
    const moveBusXTrans = useTransform(moveBusX, [0, windowWidth], [windowWidth, 0])

    const [initMuseumsX, setInitMuseumsX] = useState(0)
    const museumsRef = useRef<HTMLDivElement>(null)

    const [index, setIndex]=useState(0)
    const [houseList,setHouseList]=useState<museumDataType[]>([])

    useEffect(()=>{
        setHouseList(staggeredLengthArray(originalMuseums.filter((el: museumDataType) => el.busShown)))
    },[originalMuseums])

    // useEffect(()=>{
    //     if(Math.abs(initMuseumsX) > houseList.length*304){
    //         setIndex(prev => prev + (initMuseumsX > 0 ? -1 : 1))
    //         // setInitMuseumsX(0)
    //     }
    // },[initMuseumsX])
    // useEffect(()=>{console.log('index',index)},[index])

    useEffect(() => {
        if (busMode === busModeType.FINISH) {
            setTimeout(() => {
                setBusMode(busModeType.INITIALIZE)
                setCanSelect(false)
                moveBusX.set(0)
            }, 1100)
        } else if (busMode === busModeType.INITIALIZE) {
            setTimeout(() => {
                setBusMode(busModeType.WAITING_TO_START)
            }, 400)
        } else if (busMode === busModeType.STARTING) {
            setTimeout(() => {
                setBusMode(busModeType.STOPPING)
            }, 2000)
        } else if (busMode === busModeType.STOPPING) {
            setTimeout(() => {
                setCanSelect(true)
                setBusMode(busModeType.SELECTING)
                setIsFirstTime(false)
            }, 1000)
        }
    }, [busMode])

    const {observe: museumLeftRef, inView: museumLeftInView} = useInView({
        rootMargin: '0px 0px 0px 0px',
        threshold: [1],
    })
    const {observe: museumRightRef, inView: museumRightInView} = useInView({
        rootMargin: '0px 0px 0px 0px',
        threshold: [1],
    })

    useEffect(()=>{
        if(showSearchPage){
            setBusMode(busModeType.FINISH)
            setTimeout(()=>{changeSearchPage(true)},1000)
        }
    },[showSearchPage])

    return <div className="select-none">
        <div className="bg-wine-400 h-8 w-full fixed bottom-0 left-0"/>

        {/*關閉車車模式的 backdrop*/}
        <div
            className={`fixed w-screen h-screen top-0 left-0 transition-opacity duration-300 ${showSearchPage ? 'hidden' : ''}`}
            onClick={() => {
                if(!showSearchPage){
                    setBusMode(busModeType.FINISH)
                    setTimeout(()=>{changeSearchPage(true)},1000)
                }
            }}
        />

        {/*房子部分*/}

            <motion.div className="pointer-events-none" {...fadeProps}>
                <div className={`w-full fixed h-[220px] bottom-[32px] left-0 pointer-events-none bg-gradient-to-t from-white via-white/90 to-white/0 ${showSearchPage?'opacity-0 pointer-events-none':'opacity-100'}`}/>
                <motion.div
                    className={`flex flex-col fixed bottom-0 left-0 duration-500`}
                    initial={!showSearchPage?{opacity:0}:{opacity:1}}
                    animate={!showSearchPage?{opacity:1}:{opacity:0}}
                    transition={!showSearchPage?{delay:2,duration:0.5} : {duration:0.5}}
                >
                    <motion.div
                        className={`mb-8 flex items-end gap-12 h-60 ${showSearchPage?'pointer-events-none':'pointer-events-auto'}`}
                        ref={museumsRef}
                        drag='x'
                        // dragElastic={0}
                        dragConstraints={{
                            left:-houseList.length*336+windowWidth-250,
                            right:0
                        }}
                        initial={isFirstTime&&(busMode === busModeType.STARTING) ? {x: windowWidth - 264}
                            :{}}
                        animate={isFirstTime&&(busMode === busModeType.STARTING) ? {x: 0}
                            :{}}
                        // transition={!showSearchPage?{delay:2,duration:0.5} : {duration:0.5}}

                        // onDragEnd={(event, info)=>{
                        //     setInitMuseumsX(prev=> prev + info.offset.x +info.velocity.x+info.delta.x
                        // )
                        // }}
                        // style={canControl ? {x: moveBusXTrans}
                        //     : (busMode === busModeType.WAITING_TO_START) ? {x: moveBusXTrans}
                        //         // : (busMode === busModeType.STARTING) ? {x: windowWidth} // click版
                        //             // : (busMode === busModeType.STARTING) ? {x: windowWidth - 264} // drag版
                        //             // : (busMode === busModeType.SELECTING) ? {x: windowWidth - 264}
                        //             : (busMode === busModeType.FINISH) ? {x: initMuseumsX}
                        //                 : (busMode === busModeType.INITIALIZE) ? {x: windowWidth, opacity: 0}
                        //                     : {}}
                        // initial={canControl ? {x: windowWidth}
                        //     : busMode === busModeType.WAITING_TO_START ? {x: windowWidth}
                        //         : (busMode === busModeType.STARTING) ? {x: windowWidth - 264}
                        //             // : (busMode === busModeType.SELECTING) ? {x: initMuseumsX}
                        //                 : (busMode === busModeType.FINISH) ? {x: initMuseumsX}
                        //                     : (busMode === busModeType.INITIALIZE) ? {x: windowWidth, opacity: 0}
                        //                         : {x: 0}}
                        // animate={canControl ? {}
                        //     : (busMode === busModeType.WAITING_TO_START) ? {x: windowWidth}
                        //         : (busMode === busModeType.STARTING) || (busMode === busModeType.STOPPING)  ? {x: initMuseumsX}
                        //             : (busMode === busModeType.FINISH) ? {opacity: 0}
                        //                 : (busMode === busModeType.INITIALIZE) ? {x: windowWidth, opacity: 0}
                        //                     : {}
                        // }
                        // transition={(busMode === busModeType.STARTING) ? canSelect ? {duration: 0.5} : {duration: 2}
                        //     : (busMode === busModeType.FINISH) ? {duration: 0.5, delay: 0.5}
                        //         : (busMode === busModeType.INITIALIZE) ? {duration: 0}
                        //             : {}
                        // }
                    >
                        <div className="w-1" ref={museumLeftRef}/>
                        {/*{museums.filter((el:museumDataType)=>el.busShown).map((el:museumDataType)=>{*/}
                        {houseList.map((el: museumDataType,loopIndex:any) => {
                                return <div key={`houseTagItems${el.name}${loopIndex}`} className="flex flex-col items-center relative">
                                    {/*museum's tags*/}
                                    <motion.div className="absolute bottom-[130px] h-32 z-10 flex flex-col-reverse gap-2"
                                                variants={houseTagsContainer} initial="hidden" whileInView="visible"
                                    >
                                        {el.showTagsList.slice(0, 3).map(ele => <motion.div
                                            key={`houseTagItem${ele}${loopIndex}`}
                                            className={`odd:bg-[#277373] even:bg-[#18bebc] hover:odd:bg-[#277373]/80 hover:even:bg-[#18bebc]/80 rounded-full px-3  ${busMode === busModeType.SELECTING ? 'cursor-pointer pointer-events-auto' : 'pointer-events-none'}`}
                                            variants={houseTagsItem}
                                            onTap={() => {
                                                // changeSearchCategories(ele)
                                                changeSearchPage(true)
                                                setBusMode(busModeType.FINISH)
                                                setTimeout(()=>{changeSearchPage(true)},1000)
                                                updateSearchKeyword(ele)
                                                searchEnter(ele)
                                                topPointHandler()
                                            }}
                                        >
                                            <span className="text-sm text-white font-sans">{ele}</span>
                                        </motion.div>)}
                                    </motion.div>
                                    {/*museum's houses*/}
                                    <motion.div
                                        className={`w-72 group ${busMode === busModeType.SELECTING ? 'cursor-pointer pointer-events-auto' : 'pointer-events-none'}`}
                                        key={`Bus${el.busMediaList[0]}`}
                                        onTap={() => {
                                            // updateSelectedMuseum(el)
                                            navigate(`/museums/${el.id}`)
                                        }}
                                        layoutId={`Bus${el.name}Container`}
                                    >
                                        <motion.img src={el.busMediaList[0]}
                                                    className="first:block last:hidden group-hover:hidden w-full  object-contain pointer-events-none"/>
                                        <motion.img src={el.busMediaHover}
                                                    className="first:block last:hidden group-hover:block w-full  object-contain pointer-events-none"/>

                                    </motion.div>
                                    <div className="absolute -bottom-[28px] text-white whitespace-nowrap">{el.name}</div>
                                </div>
                            }
                        )}
                        <div className="w-1" ref={museumRightRef}/>
                    </motion.div>
                </motion.div>
            </motion.div>



        {/*偷偷下載車車圖片*/}
        <div className="hidden">
            <img src="./images/bus_go.gif" className="scale-[5] object-contain pointer-events-none"/>
            <img src="./images/bus_go_2.gif" className="scale-[5] object-contain pointer-events-none"/>
            <img src="./images/bus_wait.gif" className="scale-[5] object-contain pointer-events-none"/>
        </div>

        {/*車車部分*/}
        <div className="fixed bottom-8 md:bottom-0 -left-[70px] md:-left-[116px] flex h-[44px] md:h-20 pointer-events-none z-20 bg-transparent">
            <div className="w-[calc(25vw+70px)] md:w-[calc(25vw+116px)]">
                {busMode === busModeType.WAITING_TO_START && (
                    <motion.div
                        className="h-full w-[100px] md:w-[176px]  pointer-events-auto cursor-pointer"
                        layoutId="bus"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{
                            duration: 1,
                        }}
                        // drag="x"
                        dragConstraints={{top: 0, left: 0, right: 0, bottom: 0}}
                        dragElastic={1}
                        // onDrag={
                        //     (event, info) => {
                        //         // console.log(info.point.x, info.point.y)
                        //         setCanControl(true)
                        //         if (info.point.x < 264) {
                        //             // setBusMode(busModeType.WAITING_TO_START)
                        //             moveBusX.set(info.point.x)
                        //         } else {
                        //             setBusMode(busModeType.STARTING)
                        //             setCanControl(false)
                        //         }
                        //     }
                        // }
                        onClick={() => {
                            changeSearchPage(false)
                            setBusMode(busModeType.STARTING)
                        }}
                        // onDragEnd={(event, info) => {
                        // console.log(info.point.x, info.point.y)
                        // setCanControl(false)
                        // if (info.point.x < 264) {
                        //     setBusMode(busModeType.WAITING_TO_START)
                        //     moveBusX.set(info.point.x)
                        // } else {
                        //     setBusMode(busModeType.STARTING)
                        // }
                        // }}
                    >
                        {/*<img*/}
                        {/*    src="./images/bus_wait.avi"*/}
                        {/*    className="h-full object-contain pointer-events-none"*/}
                        {/*    // loop="-1"*/}
                        {/*/>*/}

                        <img src="./images/bus_wait.gif"
                             className="scale-[5] object-contain pointer-events-none"/>
                        {/*<img src="./images/bus-46.png" className="h-full object-contain pointer-events-none"/>*/}
                    </motion.div>
                )}
            </div>

            <div className="w-[72vw] flex flex-row-reverse">
                {(busMode === busModeType.STARTING) && (
                    <motion.div
                        className=" w-[100px] md:w-[176px] "
                        layoutId="bus"
                        transition={{duration: 2}}
                    >
                        <img src="./images/bus_go.gif"
                             className="scale-[5] object-contain pointer-events-none"/>
                        {/*<img src="./images/bus-46.png" className="h-full object-contain pointer-events-none select-none"/>*/}
                    </motion.div>
                )}
                {(busMode === busModeType.STOPPING) && (
                    <motion.div
                        className="w-[100px] md:w-[176px] "
                        layoutId="bus"
                        transition={{duration: 1}}
                    >
                        <img src="./images/bus_go_2.gif"
                             className="scale-[5] object-contain pointer-events-none"/>
                        {/*<img src="./images/bus-46.png" className="h-full object-contain pointer-events-none select-none"/>*/}
                    </motion.div>
                )}
                {(busMode === busModeType.SELECTING) && (
                    <motion.div
                        className="w-[100px] md:w-[176px]  pointer-events-auto cursor-pointer"
                        layoutId="bus"
                        transition={{duration: 2}}
                        onClick={() => {
                            if(!showSearchPage){
                                setBusMode(busModeType.FINISH)
                                setTimeout(()=>{changeSearchPage(true)},1000)
                            }else{
                                changeSearchPage(false)
                            }
                        }}
                    >
                        <img src="./images/bus_wait.gif"
                             className="scale-[5] object-contain pointer-events-none"/>
                        {/*<img src="./images/bus-46.png" className="h-full object-contain pointer-events-none select-none"/>*/}
                    </motion.div>
                )}
            </div>
            <div className="w-[3vw] flex flex-row-reverse">
                {busMode === busModeType.FINISH && (
                    <motion.div
                        className="h-full w-[100px] md:w-[176px] pointer-events-auto cursor-pointer"
                        layoutId="bus"
                        initial={false}
                        animate={{marginRight: -200}}
                    >
                        <img src="./images/bus_wait.gif"
                             className="scale-[5] object-contain pointer-events-none"/>
                        {/*<img src="./images/bus-46.png" className="h-full object-contain pointer-events-none"/>*/}
                    </motion.div>
                )}
            </div>
        </div>
    </div>
}

export default BusNavigation