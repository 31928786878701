import React, {FC, ReactNode} from "react";

const MenuItem:FC<{
    text:string;
    children:ReactNode;
    clickHandler:string|(()=>void);
    imgSrc:string;
}>=({text,children,clickHandler,imgSrc})=>{
    const handler = typeof clickHandler === 'string'?{href:clickHandler,target:'_blank'}:{onClick:clickHandler}

    return <a className="flex flex-col items-center gap-1 mini:gap-4" {...handler}>
        <div className="group cursor-pointer relative bg-mystic-500 hover:bg-wine-500 transition-colors duration-700 w-40 mini:w-48 sm:w-60 h-24 sm:h-32 rounded-xl">
            <img className="pointer-events-none absolute group-hover:opacity-30 duration-700 transition-opacity object-cover w-full h-full rounded-xl" src={imgSrc}/>
            <div className="pointer-events-none absolute opacity-0 group-hover:opacity-100 duration-700 transition-opacity w-full h-full text-white flex">
                {children}
            </div>
        </div>
        <div className="text-wine-500 tracking-widest">{text}</div>
    </a>
}
export default MenuItem