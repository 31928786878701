import axios from "axios";
import {organizer} from "../types/dataType";

const dbLink = "https://api.museum360.cloud"

const categoriesData = {}

export const getCategories=async (language='zh')=>{
    if(categoriesData&&categoriesData.hasOwnProperty(language)){
        return categoriesData[language]
    }

    const lan = (language==='en')?'&locale=en':''
    try {
        const res = await axios(`${dbLink}/api/categories?populate=recommendTagsExhibit${lan}`)
        if(res.status===200){
            const categories = []
            const resData = await res.data.data
            await resData.forEach(el=>{
                categories.push({
                    name:el.attributes.categoryName,
                    tags:el.attributes.recommendTagsExhibit.data.map(el=>el.attributes.name)
                })
            })
            // console.log(categories)
            categoriesData[language] = categories

            return categories
        }
    }catch (e) {
        console.log(e)
    }
}

export const getDistricts=async (language='zh')=>{
    const lan = (language==='en')?'?locale=en':''

    try {
        const res = await axios(`${dbLink}/api/districts${lan}`)
        if(res.status===200){
            const districts = []
            const resData = await res.data.data
            await resData.forEach(el=>{
                districts.push(el.attributes.name)
            })
            // console.log(categories)
            return districts
        }
    }catch (e) {
        console.log(e)
    }
}

const exhibitsData = {}

export const getExhibit=async (language='zh')=>{
    if(exhibitsData&&exhibitsData.hasOwnProperty(language)){
        return exhibitsData[language]
    }

    const lan = (language==='en')?'&locale=en':''
    try {
        const res = await axios(`${dbLink}/api/online-exhibits?pagination[pageSize]=100&populate=%2A${lan}`)
        if(res.status===200){
            const resData = await res.data.data
            console.log('getExhibit',resData)
            exhibitsData[language] = resData
            return resData
        }
    }catch (e) {
        console.log(e)
    }
}

const museumsData = {}

export const getMuseums=async (language='zh')=>{
    if(museumsData&&museumsData.hasOwnProperty(language)){
        return museumsData[language]
    }
    const lan = (language==='en')?'&locale=en':''
    try {
        const res = await axios(`${dbLink}/api/museums?pagination[pageSize]=100&populate=%2A${lan}`)
        if(res.status===200){
            const museums = []
            const resData = await res.data.data
            console.log('getMuseums',resData)
            museumsData[language] = resData
            return resData
        }
    }catch (e) {
        console.log(e)
    }
}

export const getOneExhibit=async (language='zh',id)=>{
    const lan = (language==='en')?'&locale=en':''
    try {
        const res = await axios(`${dbLink}/api/online-exhibits/${id}?populate=%2A${lan}`)
        if(res.status===200){
            const resData = await res.data.data

            // console.log('getOneExhibit',resData)
            return resData
        }
    }catch (e) {
        console.log(e)
    }
}

export const getOneMuseum=async (language='zh',id)=>{
    const lan = (language==='en')?'&locale=en':''
    try {
        const res = await axios(`${dbLink}/api/museums/${id}?populate=%2A${lan}`)
        if(res.status===200){
            const resData = await res.data.data

            // console.log('getOneExhibit',resData)
            return resData
        }
    }catch (e) {
        console.log(e)
    }
}

export const formatExhibit=(originalExhibitData)=>{
    return {
        id : originalExhibitData.id,
        mainMedia : originalExhibitData.attributes.mainMedia.data !== null ? `${dbLink}${originalExhibitData.attributes.mainMedia.data.attributes.url}` : '',
        exhibitMediaList : originalExhibitData.attributes.exhibitMedia.data !== null ? originalExhibitData.attributes.exhibitMedia.data.map(ele=>({name:ele.attributes.name,url:`${dbLink}${ele.attributes.url}`})) : [],
        exhibitName : originalExhibitData.attributes.exhibitName || '',
        exhibitURL : originalExhibitData.attributes.exhibitURL || '',
        exhibitDescription : originalExhibitData.attributes.exhibitDescription || '',
        tagsList : originalExhibitData.attributes.tags.data.map(ele=>ele.attributes.name) || [],
        showTagsList : originalExhibitData.attributes.tags.data.filter(ele=>ele.attributes.show).map(ele=>ele.attributes.name) || [],
        organizersList : originalExhibitData.attributes.organizers.data.map(ele=>({
            name:ele.attributes.name,
            description:ele.attributes.description,
            website:ele.attributes.websiteURL
        })) || [],
        exhibitCategories : originalExhibitData.attributes.categories.data !== null ? originalExhibitData.attributes.categories.data.map(ele => ele.attributes.categoryName) : [],
        hasInteractive : originalExhibitData.attributes.hasInteractive || false,
        // catList : originalExhibitData.attributes.cat_interact_photo.data !== null ? originalExhibitData.attributes.cat_interact_photo.data.map(ele=>(`${dbLink}${ele.attributes.url}`)) : [],
        // interactPhoto : originalExhibitData.attributes.interact_photo.data !== null ? `${dbLink}${originalExhibitData.attributes.interact_photo.data.attributes.url}` : '',
    }
}

export const formatMuseum=(originalMuseumData)=>{
    return {
        id:originalMuseumData.id,
        name : originalMuseumData.attributes.name || '',
        description : originalMuseumData.attributes.description || '',
        websiteURL : originalMuseumData.attributes.websiteURL || '',
        phoneNo : originalMuseumData.attributes.phoneNo || '',
        busShown : originalMuseumData.attributes.busShown || false,
        showInSearch : originalMuseumData.attributes.showInSearch || false,
        fbURL : originalMuseumData.attributes.fbURL || '',
        youtubeURL : originalMuseumData.attributes.youtubeURL || '',
        IGURL : originalMuseumData.attributes.IGURL || '',
        twitterURL : originalMuseumData.attributes.twitterURL || '',
        mediaList : originalMuseumData.attributes.media.data !== null ? originalMuseumData.attributes.media.data.map(ele=>({
            name:ele.attributes.name,
            url:`${dbLink}${ele.attributes.url}`,
        })) :[],
        onlineExhibitsList : originalMuseumData.attributes.onlineExhibits.data !== null ? originalMuseumData.attributes.onlineExhibits.data.map(ele=>({
            exhibitName:ele.attributes.exhibitName,
            exhibitDescription:ele.attributes.exhibitDescription,
            exhibitURL:ele.attributes.exhibitURL,
            hasInteractive:ele.attributes.hasInteractive!==null,
        })) :[],
        categoriesList : originalMuseumData.attributes.categories.data !== null ? originalMuseumData.attributes.categories.data.map(ele=>ele.attributes.categoryName) :[],
        tagsList : originalMuseumData.attributes.tags.data !== null ? originalMuseumData.attributes.tags.data.map(ele=>ele.attributes.name) :[],
        showTagsList : originalMuseumData.attributes.tags.data !== null ? originalMuseumData.attributes.tags.data.filter(ele=>ele.attributes.show).map(ele=>ele.attributes.name) :[],
        mainMedia : originalMuseumData.attributes.mainMedia.data !== null ? `${dbLink}${originalMuseumData.attributes.mainMedia.data.attributes.url}` : '', // 縮圖
        hoverMedia : originalMuseumData.attributes.hoverMedia.data !== null ? `${dbLink}${originalMuseumData.attributes.hoverMedia.data.attributes.url}` : '',
        customBannerList : originalMuseumData.attributes.customBanner.data !== null ? originalMuseumData.attributes.customBanner.data.map(ele=>`${dbLink}${ele.attributes.url}`) : [],
        busMediaList : originalMuseumData.attributes.busMedia.data !== null ? originalMuseumData.attributes.busMedia.data.map(ele=>`${dbLink}${ele.attributes.url}`) : [],
        busMediaHover : originalMuseumData.attributes.busMediaHover.data !== null ? `${dbLink}${originalMuseumData.attributes.busMediaHover.data.attributes.url}` : '',
        district : originalMuseumData.attributes.district.data !== null ? originalMuseumData.attributes.district.data.attributes.name : '',
        openningHourList: [
            originalMuseumData.attributes.openningHourMon || '',
            originalMuseumData.attributes.openningHourTue || '',
            originalMuseumData.attributes.openningHourWed || '',
            originalMuseumData.attributes.openningHourThur || '',
            originalMuseumData.attributes.openningHourFri || '',
            originalMuseumData.attributes.openningHourSat || '',
            originalMuseumData.attributes.openningHourSun || '',
        ],
    }
}