import React, {FC, useContext, useEffect, useState} from 'react';
import SearchCtx from "../../store/SearchCtx";
import {AnimatePresence, motion} from "framer-motion";
import LanguageCtx from "../../store/LanguageCtx";
import {isTablet} from "react-device-detect";
import LoadImage from "../UI/LoadImage";

const IntroCard:FC<{
    key?: string;
    picSrc?:string;
    hoverPicSrc?:string;
    title:string;
    subTitleList?:string[];
    tagList:string[];
    isSelected:boolean;
    onClick:()=>void;
    idHook:string;
}> = ({picSrc,hoverPicSrc, title, subTitleList,
                          tagList, isSelected, onClick,idHook}) => {
    const {isEn} = useContext(LanguageCtx)
    const [isHover,setIsHover]=useState(false)
    const [isOpen,setIsOpen] =useState(false)

    useEffect(()=>{
        if(isSelected){
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }
    },[isSelected])

    return <div className={`group justify-self-center flex justify-around w-[90%] rounded-xl p-6 pb-4 m-2 hover:bg-white/80 transition-all duration-300 cursor-pointer `} //${isOpen?'opacity-0':'opacity-1'}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={onClick}
                id={idHook}
    >

        <motion.div layoutId={`${title}Container`} className={`transition-opacity w-full flex flex-col justify-between text-left ${isEn?'':'tracking-widest'}`}>
            <div className="relative">
                { hoverPicSrc && picSrc && <>
                    <LoadImage src={hoverPicSrc} className="absolute w-full object-contain group-hover:opacity-0 transition-opacity"/>
                    <LoadImage className={"w-full object-contain opacity-0 group-hover:opacity-100"} src={picSrc}/>
                    {/*<motion.img layoutId={`${title}Image`} className={"w-full object-contain opacity-0 group-hover:opacity-100"} src={picSrc}/>*/}
                </>}
                { hoverPicSrc && !picSrc && <>
                    <LoadImage
                        // layoutId={`${title}Image`}
                        className={"w-full object-contain"} src={hoverPicSrc} alt=""/>
                </>}

                {/*{hoverPicSrc&& <img src={hoverPicSrc} className="absolute w-full object-contain hover:opacity-0 transition-opacity" alt=""/>}*/}
                {picSrc&&!hoverPicSrc&&<LoadImage
                    // layoutId={`${title}Image`}
                    className={"w-full object-contain"} src={picSrc} alt=""/>}
                {!picSrc && !hoverPicSrc && <motion.div  className={"w-full h-[152px] object-contain bg-wine-100 flex items-center justify-center"}>No Data</motion.div>}
                <motion.div
                    // layoutId={`${title}Title`}
                    className={"font-serif text-xl font-bold text-wine-500 mt-1"}

                >{title}</motion.div>
                <motion.div className={"text-gray-500 text-sm mt-1"}
                            // layoutId={`${title}Organizers`}
                >{subTitleList && subTitleList.map((el:any)=><span key={`${el}${Math.random()}`} className={`${isEn&&''} border-r-2 border-wine-300 pr-2 mr-2 last:border-0 break-words`}>{el}</span>)}</motion.div>
            </div>


            <AnimatePresence>
                {tagList.length > 0 &&
                    <motion.div
                        className={`transition-opacity mt-2 ${isTablet?'hidden':'hidden md:block'}`}
                        initial={{opacity: 0}}
                        animate={isHover ? {opacity: 1} : {opacity: 0}}
                        exit={{opacity: 0}}
                        transition={{ease: "easeOut", duration: 0.3}}
                        layoutId={`${title}Tags`}
                    >
                        {tagList.slice(0, 4).map((el: any) => <span key={`${title}_${el}`}
                                                                    className="rounded-full text-sm inline-block mt-1 mr-2 bg-[#e4eaee] py-[2px] px-2 text-[#4c5376] whitespace-nowrap">
                    {el}
                </span>)}
                    </motion.div>
                }
            </AnimatePresence>

            {/*一樣的標籤手機板再定義一次*/}
            {tagList.length>0&&
                <motion.div
                    className={`mt-2 ${isTablet?'block':'block md:hidden'}`}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                    layoutId={`${title}TagsMobile`}
                >
                    {tagList.filter((el:any)=>el.length<15).slice(0,4).map((el: any) => <span key={`${title}_${el}`} className="rounded-full border border-wine-400  text-sm inline-block mt-1 mr-2 bg-[#e4eaee] py-[2px] px-2 text-[#4c5376] whitespace-nowrap">
                    {el}
                </span>)}
                </motion.div>
            }
        </motion.div>
    </div>
}

export default IntroCard