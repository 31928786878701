import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { proxy, useSnapshot } from 'valtio';
import Spinner from "./Spinner";

interface ImageProps {
    src: string;
    alt?: string;
    className?:string;
}

const state = proxy({ isLoading: true });

const LoadImage = ({ src, className, alt="" }: ImageProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const { isLoading } = useSnapshot(state);

    const handleImageLoad = () => {
        state.isLoading = false;
        setImageLoaded(true);
    };

    return (
        <>
            {isLoading && <Spinner/>}
            <img
                className={`${isLoading&&'opacity'} ${!imageLoaded && 'hidden'} ${className&&className}`}
                src={src}
                alt={alt}
                onLoad={handleImageLoad}
            />
        </>
    );
};

export default LoadImage;