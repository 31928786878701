import React, {FC, useContext} from "react";
import searchCtx from "../../store/SearchCtx";
import {motion} from "framer-motion";
import useCardControl from "../../hooks/useCardControl";
import {useParams} from "react-router";

const CategoryTags:FC<{tagList:string[]}>=({tagList})=>{
    const {categoriesTagsColor,searchEnter,updateSearchKeyword,topPointHandler,changeSearchPage} = useContext(searchCtx)
    const {searchIconHandler, closeOneCard, closeAllCard} = useCardControl()
    const {exhibitId,museumId,exhibitId2,museumId2} = useParams()
    let timer = setTimeout(()=>{})

    return <div className="flex px-10 flex-wrap">
        {tagList.length>1 && tagList.map((el,i)=> {
            return <motion.div
                key={`${el}${i}`}
                className="cursor-pointer rounded-full text-sm inline-block my-1 mr-2 px-4 py-2 whitespace-nowrap hover:opacity-80"
                style={{
                    backgroundColor:`#${categoriesTagsColor[i]}`
                }}
                onClick={() => {
                    changeSearchPage(true)
                    searchIconHandler()
                    if(exhibitId||museumId){
                        clearTimeout(timer)
                        timer = setTimeout(()=>{
                            updateSearchKeyword(el)
                            searchEnter(el)
                        },1200)
                    }else{
                        updateSearchKeyword(el)
                        searchEnter(el)
                    }
                }}
            >
                {el}
            </motion.div>
        })}
    </div>
}

export default CategoryTags