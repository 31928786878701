import {useContext} from "react";
import searchCtx from "../store/SearchCtx";
const ErrorModal=()=>{

    const { errorMessage,updateErrorMessage }=useContext(searchCtx)

    if(errorMessage.title!==''){
        return <div className="modal fixed opacity-100 pointer-events-auto visible">
            <div className="modal-box">
                <h3 className="font-bold text-lg">{errorMessage.title}</h3>
                <p className="py-4">{errorMessage.content}</p>
                <div className="modal-action">
                    <label htmlFor="my-modal" className="btn" onClick={()=>{updateErrorMessage({title:'',content:''})}}>okay</label>
                </div>
            </div>
        </div>
    }

    return <></>
}
export default ErrorModal