import React, {FC, useEffect, useState} from 'react'
import {scrollToId} from "../../utils/slide";

const BannerButton:FC<{
    key?:string;
    targetId:string;
    isActive:boolean;
    changeShowImageName: (a:string)=>void;
}>=({targetId,isActive,changeShowImageName})=>{

    return <span
        className={`h-2 w-2 rounded-full pointer-events-none ${targetId==="handBanner"&&'hidden lg:block'} ${isActive?'bg-wine-700':'bg-wine-400'}`}
        onClick={()=>{
            changeShowImageName(targetId)
            scrollToId(targetId)
        }}></span>
}

export default BannerButton