import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion, useMotionValue } from "framer-motion";
import {useNavigate} from "react-router-dom";
import {useAtom, Provider, atom} from 'jotai'
import { isIOS,isMobile } from 'react-device-detect';
import SearchContext from "../../store/SearchCtx";
import useWindowSize from "../../hooks/useWindowSize";

export const isIntroPageOpenAtom = atom(true)

const IntroPage=()=>{
    const {topPointHandler} = useContext(SearchContext)

    const [isIntroPageOpen,setIsIntroPageOpen] = useAtom(isIntroPageOpenAtom)
    const [showSkip,setShowSkip]=useState(false)

    const [isEnded, setIsEnded] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const {width:windowWidth,height:windowHeight} = useWindowSize()

    const handleEnded = () => {
        setIsEnded(true);
    };

    const [loadingVideo, setLoadingVideo] = useState(true);

    useEffect(()=>{
        const haveSeenIntro = localStorage.getItem("haveSeenIntro")
        if (haveSeenIntro) {
            setIsIntroPageOpen(false)
        }
    },[])

    useEffect(()=>{
        if(isIntroPageOpen){
            setIsEnded(false)
            const haveSeenIntro = localStorage.getItem("haveSeenIntro")
            if(haveSeenIntro){
                setShowSkip(true)
            }
        }
    },[isIntroPageOpen])

    useEffect(()=>{
        if(isEnded) {
            topPointHandler()
            setTimeout(() => {
                localStorage.setItem("haveSeenIntro", "yes")
                setIsIntroPageOpen(false)
            }, 3000)
        }
    },[isEnded])

    useEffect(()=>{
        setTimeout(()=>{
            if(loadingVideo){
                setShowSkip(true)
            }
        },8000)
    },[])

    // useEffect(()=>{
    //     setIsIntroPageOpen(false)
    // },[])

    return <AnimatePresence>
        {/*{isIOS&&<div className="z-[120]" onClick={() => {*/}
        {/*    if (videoRef && videoRef.current) {*/}
        {/*        videoRef.current.muted = true;*/}
        {/*        videoRef.current.play();*/}
        {/*    }*/}
        {/*}}>play</div>}*/}
        {isIntroPageOpen && <motion.div className="fixed top-0 left-0 w-screen h-screen pointer-events-auto"
                               initial={{opacity:0}}
                               animate={{opacity:1}}
                               exit={{opacity:0}}
                               transition={{duration:0.5}}
        >
            {isEnded && <div
                className="relative mt-[116px] md:mt-[148px] z-[51] w-[70%] max-w-[720px] md:w-[50%] h-12 md:h-16 flex items-center justify-center mx-auto"
            >
                <motion.div className="h-full w-12 md:w-16 bg-wine-400 rounded-full "
                            initial={window.innerWidth>768?
                                {width: 64, y: -300,} :{width: 48, y: -300,}}
                            animate={{
                                width:'100%',
                                y: 0,
                                transition: {
                                    y:{
                                        delay:1,
                                        duration: 1,
                                        type:"spring"
                                    },
                                    width:{
                                        delay:2,
                                        duration: 2,
                                        type:"spring"
                                    }
                                }
                            }}
                ></motion.div>
            </div>}
            {isEnded&&<div className="fixed bottom-8 md:bottom-0 -left-[70px] md:-left-[116px] flex h-[44px] md:h-20 pointer-events-none z-20">
                <div className="w-[calc(25vw+70px)] md:w-[calc(25vw+116px)]">
                    <motion.div className="h-full w-[100px] md:w-[176px]  pointer-events-auto cursor-pointer"
                                initial={{translateX:-72}}
                                animate={{
                                    translateX:0,
                                    transition:{
                                        translateX:{delay:2,duration:1,type:"spring"}
                                    }
                                }}
                    >
                        <img src="./images/bus_wait.gif" className="scale-[5] object-contain pointer-events-none"/>
                    </motion.div>
                </div>
            </div>}
            {isEnded&&<motion.div
                initial={{translateY:32}}
                animate={{
                    translateY:0,
                    transition:{
                        translateY:{delay:1,duration:1,type:"spring"}
                    }
                }}
                className="footer footer-center bg-wine-400 h-8 text-white fixed bottom-0 transition-opacity"></motion.div>}

            <div className={`h-screen screen transition-opacity duration-1000 flex justify-center items-center ${isEnded && 'opacity-0'}`}>
                {loadingVideo && (
                    <div className="fixed top-0 left-0 flex flex-col items-center justify-center text-wine-500 h-full w-full bg-gray-300">
                        Loading...
                        <progress className="progress w-56 after:bg-yellow-500"></progress>
                    </div>
                )}
                <video playsInline autoPlay muted className={`object-cover h-full w-full fixed top-0 left-0 ${loadingVideo ? 'hidden' : ''}`}
                       onLoadedData={() => setLoadingVideo(false)}
                       onEnded={handleEnded} ref={videoRef}>
                    {windowWidth<640
                        ?<source src="./videos/vm_ph.mp4" type="video/mp4"/>
                        :<source src="./videos/vm_pc.mp4" type="video/mp4"/>}
                </video>
            </div>

            {showSkip&&<motion.div className="fixed bottom-4 right-4 md:right-6 lg:right-8 cursor-pointer text-white font-bold text-xl animate-pulse drop-shadow-md"
                                   whileHover={{scale:1.1}}
                                   onClick={()=>{
                                        setIsEnded(true)
                                        setShowSkip(false)
                                    }}>{"SKIP>>"}</motion.div>}
            </motion.div>}
    </AnimatePresence>
}

export default IntroPage