import {motion} from "framer-motion";
import {AiFillFacebook, AiFillHome, AiFillYoutube} from "react-icons/ai";
import React, {FC, ReactNode, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useAtom} from "jotai/index";
import {isIntroPageOpenAtom} from "../Intro/IntroPage";
import searchCtx from "../../store/SearchCtx";
import LanguageCtx from "../../store/LanguageCtx";
import MenuItem from "./MenuItem";
import {isOpenAboutPageAtom} from "../About";
import {isOpenNavigationAtom} from "../../App";

const LinkIcon:FC<{children:ReactNode,url:string}> =({children,url})=>{
    return <motion.a className="h-6 cursor-pointer" href={url} target="_blank"
                     whileHover={{scale:1.2}} whileTap={{scale:0.9}}
    >{children}</motion.a>
}

const MenuContainer:FC<{
    isOpen:boolean;
    closeHandler:()=>void;
    closeAllCard:()=>void;
}>=({isOpen,closeHandler,closeAllCard})=>{
    const navigate = useNavigate()
    const [isIntroPageOpen,setIsIntroPageOpen] = useAtom(isIntroPageOpenAtom)
    const [isOpenAboutPage,setIsOpenAboutPage] = useAtom(isOpenAboutPageAtom)
    const [ isOpenNavigation,setIsOpenNavigation ] = useAtom(isOpenNavigationAtom)

    const {topPointHandler} = useContext(searchCtx)
    const {uiText} = useContext(LanguageCtx)

    return <div
        className={`fixed z-[60] top-[62px] bg-gradient-to-r from-gray-100 to-white w-full left-0 transition-opacity ${isOpen?'pointer-events-auto opacity-100':'pointer-events-none opacity-0'}`}>
        <footer className="footer p-4 mini:p-10 bg-base-200 text-base-content justify-center">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                <MenuItem
                    text={uiText.menu.search}
                    imgSrc={"./images/menu/search.png"}
                    clickHandler={()=>{
                        closeAllCard()
                        topPointHandler()
                        setIsOpenAboutPage(false)
                        setIsOpenNavigation(false)
                    }}
                >
                    <SearchIcon/>
                </MenuItem>
                <MenuItem
                    text={uiText.menu.report}
                    imgSrc={"./images/menu/report.png"}
                    clickHandler={"https://forms.gle/mo321C4Q4BdoXk2F7"}
                >
                    <ReportIcon/>
                </MenuItem>
                <MenuItem
                    text={uiText.menu.animation}
                    imgSrc={"./images/menu/animation.png"}
                    clickHandler={()=>{
                        closeAllCard()
                        setIsIntroPageOpen(true)
                        setIsOpenNavigation(false)
                        setIsOpenAboutPage(false)
                    }}
                >
                    <AnimationIcon/>
                </MenuItem>
                <MenuItem
                    text={uiText.menu.about}
                    imgSrc={"./images/menu/aboutUs.png"}
                    // clickHandler={"https://www.panosensing.com.tw/about/team/"}
                    clickHandler={()=>{
                        navigate('about')
                        setIsOpenNavigation(false)
                        setIsOpenAboutPage(true)
                    }}
                >
                    <AboutUsIcon/>
                </MenuItem>
            </div>


            {/*<div>*/}
                {/*<span className="footer-title" >Services</span>*/}
                {/*<a className="link link-hover">{uiText.menu.search}</a>*/}
                {/*<a className="link link-hover" href="https://forms.gle/mo321C4Q4BdoXk2F7" target="_blank">*/}
                {/*    {uiText.menu.report}*/}
                {/*</a>*/}
                {/*<a className="link link-hover" onClick={()=>{*/}
                {/*    closeHandler()*/}
                {/*    backCard()*/}
                {/*    setIsIntroPageOpen(true)*/}
                {/*}}>{uiText.menu.animation}</a>*/}

            {/*</div>*/}
            {/*<div>*/}
            {/*    <span className="footer-title">ABOUT</span>*/}
            {/*    <a className="link link-hover" href="https://www.panosensing.com.tw/about/team/" target="_blank">{uiText.menu.about}</a>*/}

            {/*</div>*/}
        </footer>
        <footer className="px-10 py-4 border-t bg-base-200 text-base-content border-base-300 flex justify-between flex-wrap gap-4">
            <div className="items-center flex flex-nowrap gap-1">
                {/*<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg>*/}
                <span>Copyright © 2023</span><span className="hidden md:block">- All right reserved by</span> <span>Panosensing Ltd.</span>
            </div>
            <div className="md:place-self-center md:justify-self-end">
                <div className="grid grid-flow-col gap-4">
                    <LinkIcon url="https://www.panosensing.com.tw/"><AiFillHome/></LinkIcon>
                    <LinkIcon url="https://www.youtube.com/channel/UC5yYqBTrisHNbwkNrFNSFKQ"><AiFillYoutube/></LinkIcon>
                    <LinkIcon url="https://www.facebook.com/panosensing/?locale=zh_TW"><AiFillFacebook/></LinkIcon>
                </div>
            </div>
        </footer>
    </div>

}
export default MenuContainer

const SearchIcon=()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 104">
        <circle fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="8px" cx="137.58" cy="43.87" r="25.47" transform="translate(-6 59.31) rotate(-23.82)"/>
        <line fill="none" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeWidth="9px" x1="108.95" y1="83.5" x2="121.31" y2="67.04"/>
    </svg>
}

const AboutUsIcon=()=>{
    return <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 104">
        <path d="M38.29,57V84.67H30.2V43a15.69,15.69,0,0,1,5.92-12.31,21.18,21.18,0,0,1,14.3-5.09H70.66V43a15.7,15.7,0,0,1-5.93,12.31,21.23,21.23,0,0,1-14.31,5.09A21.9,21.9,0,0,1,38.29,57Zm3.56-6.53a12.7,12.7,0,0,0,8.57,3.06A12.71,12.71,0,0,0,59,50.42a9.43,9.43,0,0,0,0-14.76,12.71,12.71,0,0,0-8.58-3.06,12.7,12.7,0,0,0-8.57,3.06,9.43,9.43,0,0,0,0,14.76Z"/>
        <path d="M93.71,25.52A21.16,21.16,0,0,1,108,30.61a15.7,15.7,0,0,1,5.93,12.31v17.4H93.71a21.19,21.19,0,0,1-14.31-5.1,15.75,15.75,0,0,1,0-24.61A21.18,21.18,0,0,1,93.71,25.52ZM81.57,42.92a9.42,9.42,0,0,0,3.56,7.38,13.56,13.56,0,0,0,17.16,0,9.45,9.45,0,0,0,0-14.76,13.56,13.56,0,0,0-17.16,0A9.42,9.42,0,0,0,81.57,42.92Z"/>
        <path d="M136.85,25.52a21.2,21.2,0,0,1,14.31,5.09,15.72,15.72,0,0,1,5.92,12.31v17.4H149V42.92a9.42,9.42,0,0,0-3.56-7.38,13.56,13.56,0,0,0-17.16,0,9.45,9.45,0,0,0,.4,15.07v9.71h-12V42.92a15.67,15.67,0,0,1,5.93-12.31A21.16,21.16,0,0,1,136.85,25.52Z"/>
        <path d="M180.22,25.52a21.16,21.16,0,0,1,14.3,5.09,15.73,15.73,0,0,1,0,24.61,22.61,22.61,0,0,1-28.61,0,15.75,15.75,0,0,1,0-24.61A21.18,21.18,0,0,1,180.22,25.52Zm0,27.84a12.71,12.71,0,0,0,8.58-3.06,9.45,9.45,0,0,0,0-14.76,13.56,13.56,0,0,0-17.16,0,9.45,9.45,0,0,0,0,14.76A12.71,12.71,0,0,0,180.22,53.36Z"/>
        <path d="M229.94,32.53a20.56,20.56,0,0,0-11.36,3q-4.68,3.06-4.69,7.38,0,3.36,6.74,9.93,9.31,9.08,9.31,14.42,0,7.2-7.85,12.28t-18.93,5.1v-7a20.41,20.41,0,0,0,11.37-3q4.71-3.06,4.71-7.38,0-3.45-6.76-9.93-9.31-8.91-9.32-14.42,0-7.19,7.86-12.28t18.92-5.1Z"/>
        <path d="M174.83,43c0-2.43,2.35-4.42,5.18-4.42s5.37,1.95,5.37,4.46-2.4,4.45-5.33,4.45S174.83,45.42,174.83,43Z"/>
        <rect x="232.93" y="77.16" width="8.88" height="8.03"/>
        <path d="M131.36,84.6c-.89.5-1.91,1-2.8,1.46a8.63,8.63,0,0,0,3.38,1.78,6.15,6.15,0,0,0-1.17,1.54,9.61,9.61,0,0,1-6-5.95,13.86,13.86,0,0,1-2.39,1.71v2.22l3.34-.72a9.12,9.12,0,0,0,0,1.48c-4.06,1-4.69,1.19-5.08,1.43v0a5.7,5.7,0,0,0-.67-1.41,1.44,1.44,0,0,0,.78-1.39V86a18.25,18.25,0,0,1-2.11.85,7.07,7.07,0,0,0-1-1.24c-2,.61-4,1.2-5.63,1.65l-.39-1.84c.7-.15,1.48-.37,2.35-.61V79.07H112.1V77.31H114V72.83h-2.06V71h5.79v1.8h-2v4.48h1.73v1.76h-1.73v5.27l1.84-.54.22,1.56a18,18,0,0,0,4.91-2.15h-2.94V78.63h10.45v4.58h-3.8a7.18,7.18,0,0,0,1.13,1.8A22.64,22.64,0,0,0,130,83.39Zm.13-7H118.44V76h13.05Zm-.7-2.54h-11.5V70.4h11.5Zm-8.12-3.25h-1.73v1.84h1.73Zm-1.08,9.92h6.73V80.09h-6.73Zm4.25-9.92h-1.78v1.84h1.78Zm3.22,0h-1.85v1.84h1.85Z"/>
        <path d="M141.69,84a10.19,10.19,0,0,0,.44,1.54c-3.2,1.76-3.74,2.15-4.09,2.52a4.56,4.56,0,0,0-.71-1.41,1.53,1.53,0,0,0,.65-1.41V79h-1c-.09,4.54-.42,8.06-2.2,10.32A4.25,4.25,0,0,0,133.51,88C135,86.1,135.24,83,135.31,79h-1.74V77.18h3.08V70.06h1.7v7.12h3.08V79h-1.82v6Zm-6.9-7.45a43,43,0,0,0-1-5l1.37-.3a42,42,0,0,1,1.06,4.9Zm6.71-5c-.5,1.7-1.13,3.76-1.63,5l-1.2-.33A37.24,37.24,0,0,0,140,71.21Zm12.05,17.29H144.3v.76h-1.76V82.45a12.54,12.54,0,0,1-.94,1,13.82,13.82,0,0,0-1.17-1.37,11.32,11.32,0,0,0,3-4.34l1.63.48c-.15.34-.28.69-.46,1h2.83c-.2-.43-.39-.89-.57-1.28l1.52-.48a15.52,15.52,0,0,1,.83,1.76h4v1.43h-3.86V82h3.4v1.37h-3.4v1.3h3.4V86h-3.4v1.35h4.19ZM141.5,75.72c.93-.39,2.19-1,3.56-1.69l.3,1.08c-1,.66-2.08,1.28-3.06,1.85Zm4.1-3.84h-3.93V70.45h5.45v6.08c0,.69-.13,1-.65,1.17a8.61,8.61,0,0,1-2.5.17,3.69,3.69,0,0,0-.35-1.08c.76,0,1.46,0,1.68,0s.3-.07.3-.26Zm-3,.15c.71.41,1.69,1,2.21,1.37l-.82,1a21.48,21.48,0,0,0-2.2-1.48Zm5,8.64H144.3V82h3.34Zm0,2.65H144.3v1.3h3.34Zm-3.34,4h3.34V86H144.3Zm7-12.27c-1.07.7-2.13,1.39-3.11,2l-.82-1.22c.93-.41,2.23-1.1,3.6-1.84Zm.19-3.21h-4V70.47H153v6.08c0,.74-.13,1-.65,1.26a8.58,8.58,0,0,1-2.5.19,6.49,6.49,0,0,0-.34-1.21,16.75,16.75,0,0,0,1.69,0c.22,0,.28-.07.28-.28ZM150,74.31a19.85,19.85,0,0,0-2.37-1.39l.76-1a24.38,24.38,0,0,1,2.37,1.28Z"/>
        <path d="M175.06,75.7v1.48h-3.73L171.15,79H158.74l.23-1.82h-3.43V75.7h3.63l.22-1.74h12.24l-.17,1.74Zm-6.64,10.94c2.21.57,4.47,1.26,6,1.76L173,89.68c-1.52-.58-4.08-1.43-6.56-2.1l.85-.94h-4.41l1.28.87A24.86,24.86,0,0,1,157,89.66a14,14,0,0,0-1.09-1.39,26.8,26.8,0,0,0,6.21-1.63h-3.71V79.78h13.85v6.86ZM158,75.07h-1.82V71.49h8.12c-.24-.52-.55-1-.81-1.48l1.74-.5a9.54,9.54,0,0,1,1.17,2h7.93v3.58h-1.91v-2H158Zm2.32,6.64h9.86v-.82h-9.86Zm0,1.89h9.86v-.84h-9.86Zm0,1.94h9.86v-.87h-9.86Zm.31-7.63h3.54c0-.3.08-.6.11-.93h-3.54Zm.37-2.84-.11.83h3.52l.06-.83Zm8.38,2.84.08-.93H166c0,.33-.09.63-.11.93Zm.24-2.84H166.2l-.08.83h3.47Z"/>
        <path d="M184,84.3c-2.22.91-4.59,1.86-6.45,2.58l-.68-2.06c.74-.24,1.63-.52,2.61-.87V76.79h-2.26V74.88h2.26V70h2v4.93h2v1.91h-2v6.44l2.12-.78Zm10.18,3.43a2.06,2.06,0,0,0,.63-.07.59.59,0,0,0,.3-.45,11.12,11.12,0,0,0,.11-1.74,5.25,5.25,0,0,0,1.59.85,7.94,7.94,0,0,1-.29,2.06,1.45,1.45,0,0,1-.84.74,4.32,4.32,0,0,1-1.33.19h-1.43a2.66,2.66,0,0,1-1.54-.45c-.39-.31-.54-.7-.54-1.91V84h-1.76c-.4,3-1.48,4.69-5.5,5.69a4.62,4.62,0,0,0-1-1.58c3.45-.68,4.3-1.85,4.6-4.11h-2V77.7h10V84h-2.43v3.07c0,.3,0,.49.15.56a.75.75,0,0,0,.48.11Zm2.06-11H184.14V75.07H187c-.15-.54-.36-1.24-.58-1.8l1.63-.37a12.23,12.23,0,0,1,.73,2.06l-.41.11h3.06c.26-.67.61-1.5.78-2.13l1.85.37c-.3.61-.56,1.22-.85,1.76h3Zm-.56-4h-10.9V71.12h4.47a11.07,11.07,0,0,0-.48-1.24l1.87-.37a11.3,11.3,0,0,1,.69,1.61h4.35Zm-8.73,7.4h6.25V79h-6.25Zm0,2.48h6.25V81.45h-6.25Z"/>
    </svg>
}

const AnimationIcon=()=>{
    return <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 104">
        <path d="M135.6,74.77,144.46,69a1.46,1.46,0,0,0,0-2.45l-8.86-5.73a1.46,1.46,0,0,0-2.25,1.23V73.54A1.46,1.46,0,0,0,135.6,74.77Z"/>
        <path d="M175.68,41.56a4.33,4.33,0,0,0-4.33-4.33H102a4.33,4.33,0,0,0-4.34,4.33V52.23h0V83.4a8.84,8.84,0,0,0,8.83,8.83H166.8a8.84,8.84,0,0,0,8.84-8.83V52.23h0Zm-5.92-1.62-1.53,3.68-7.54,0h-.45l1.52-3.66Zm-13,0-1.52,3.66h-8l1.52-3.66Zm-13,0-1.52,3.66h-8l1.53-3.67Zm-13,0-1.53,3.68h-8l1.54-3.69Zm-13,0-1.54,3.7h-8l1.54-3.71ZM167.64,83.4a.84.84,0,0,1-.84.83H106.56a.84.84,0,0,1-.83-.83V52.23h61.91Z"/>
        <path d="M174.77,24.61,173.49,16a2.71,2.71,0,0,0-.68-1.41,4,4,0,0,0-4.09-2l-69,10.67a4,4,0,0,0-3.27,3,2.62,2.62,0,0,0-.14,1.31l1.28,8.57a2.68,2.68,0,0,0,3,2.25l71.87-10.76A2.68,2.68,0,0,0,174.77,24.61ZM105.59,35.84,107,30.28l7.93-1.23-1.4,5.61Zm12.86-1.92,1.41-5.64,7.92-1.22-1.42,5.68ZM131.31,32l1.43-5.71,7.92-1.22-1.44,5.75Zm12.86-1.91,1.44-5.79,7.93-1.23-1.46,5.84ZM157,28.17l1.47-5.86,7.92-1.23L164.94,27Z"/>
    </svg>
}

const ReportIcon=()=>{
    return <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 104">
        <path d="M173.52,35.14a3.94,3.94,0,0,0-1.22-1.22l-8.44-5.33V21.51a4,4,0,0,0-4-4H146.32l-7.74-4.89a4,4,0,0,0-4.23,0l-8,4.92H112.14a4,4,0,0,0-4,4v7.21l-8.4,5.17a4.06,4.06,0,0,0-1.26,1.25,4,4,0,0,0-1.44,3v40A8.85,8.85,0,0,0,105.88,87h60.24A8.85,8.85,0,0,0,175,78.17v-40A4,4,0,0,0,173.52,35.14ZM167,75.85,151.42,58.17,167,44.41ZM116.14,25.51h39.72V42.62h0a3.86,3.86,0,0,0,.1.84l-19.4,17.19-.11.1-.06,0L116.14,42.09V25.51ZM105,42.76,119,55.58a3,3,0,0,0-.85.66L105,71.15ZM122.65,60.2a2.83,2.83,0,0,0,.49-.81l7.78,7.15a7.91,7.91,0,0,0,11,0l5-4.42L161.74,79H106.13Z"/>
        <path d="M123.15,34h25.7a1.5,1.5,0,0,0,0-3h-25.7a1.5,1.5,0,1,0,0,3Z"/>
        <path d="M123.61,37.9a1.5,1.5,0,0,0,0,3h25.7a1.5,1.5,0,0,0,0-3Z"/>
    </svg>
}