import React, {FC, useContext} from 'react';
import {motion} from "framer-motion";
import searchCtx from "../../store/SearchCtx";
import {useNavigate} from "react-router-dom";

const CardTagList: FC<{
    key?: string;
    name: any;
    tagList: string[];
    isHide: boolean;
    layoutName: string;
}> = (props) => {
    const {name, tagList, isHide, layoutName} = props
    const {
        topPointHandler,
        checkResultExhibit,
        updateSearchKeyword,
        searchEnter,
        changeSearchPage,
    }=useContext(searchCtx)
    const navigate = useNavigate()

    const clickHandler=(value:string)=>{
        topPointHandler()
        changeSearchPage(true)
        // updateSelectedMuseum(undefined)
        // updateSelectedExhibit(undefined)
        navigate('/')
        updateSearchKeyword(value)
        searchEnter(value)
    }

    return <>
    <motion.div
            className={`text-left hidden md:flex items-center transition-opacity flex-wrap ${isHide && 'opacity-0'}`}
            // layoutId={`${layoutName}${name}Tags`}
        >
            {tagList.map((el: any) => <span
                key={`${name}_${el}2`}
                className="cursor-pointer rounded-full text-sm inline-block my-1 mr-2 bg-tag-bg px-2 text-tag-text whitespace-nowrap hover:opacity-80"
                onClick={()=>{clickHandler(el)}}
            >
                                    {el}
                                </span>)}
        </motion.div>

        {/*一樣的標籤手機板再定義一次*/}
        <motion.div
            className={`text-left flex flex-wrap md:hidden items-center transition-opacity ${isHide && 'opacity-0'}`}
            layoutId={`${layoutName}${name}TagsMobile`}
        >
            {tagList.map((el: any) => <span
                key={`${name}_${el}2`}
                className="cursor-pointer rounded-full text-sm inline-block mr-2 my-1 bg-[#e4eaee] px-2 text-[#4c5376] whitespace-nowrap"
                onClick={()=>{clickHandler(el)}}
            >
                                    {el}
                                </span>)}
        </motion.div>
    </>
}

export default CardTagList