import React, {useContext, useEffect, useState} from 'react';
import TagBarItem from "../TagBarItem";
import SearchCtx from "../../store/SearchCtx";
import DownArrow from "../icons/DownArrow";
import {motion,AnimatePresence} from "framer-motion";

const SearchTagsContainer:React.FC<{
    key?:string;
    className?:string;
    value:string;
    tagsList:string[];
}> = (props) => {
    const {className,value,tagsList} = props
    const {language, searchCategories,searchRegion,changeSearchCategories, changeSearchRegion}=useContext(SearchCtx)

    const tagTitle = language==='en'?((value==="categories")?"category":"region"):((value==="categories")?"類型":"地區")
    const selectedTag = ()=>{ return(value==="categories")?searchCategories:searchRegion}

    const [isOpen,setIsOpen]=useState(false)


    {/*原來的設計*/}
    // return <div className={`mb-4 flex flex-col md:flex-row select-none`}>
    //     <div className={"p-2 mr-3 text-left text-wine-500 font-bold font-serif whitespace-nowrap"}>{tagTitle}</div>
    //     <div className={`border-t-2 md:border-t-0 md:border-l-2 border-wine-500 grid grid-cols-4 md:flex items-center justify-around`}>
    //         {tagsList.map(el=><TagBarItem key={el} value={el} type={value}/>)}
    //     </div>
    // </div>

    {/*改版的設計*/}
    return <div className={"relative"}>
        <div className={`mb-4 flex flex-row select-none`}>
            <div className={"hidden lg:block p-2 mr-3 text-left text-wine-500 font-bold font-serif whitespace-nowrap font-sans"}>{tagTitle}</div>
            <div className={`flex flex-wrap lg:flex-nowrap items-center justify-around border-wine-500 lg:border-l-2 `}>
                {tagsList.map(el=> <TagBarItem key={el} value={el} type={value}/>)}
            </div>
        </div>

        {/*SelectBox*/}
        {/*<div className={`mb-4 flex lg:hidden flex-row select-none border-[2px] rounded-full border-wine-300 p-2 cursor-pointer`}*/}
        {/*     onClick={()=>{setIsOpen(true)}}*/}
        {/*>*/}
        {/*    <div className={"flex items-center text-wine-500 mx-4 gap-4 md:gap-6"}>*/}
        {/*        <div className={"min-w-[60px] sm:min-w-[80px] md:min-w-[120px]"}>{searchCategories}</div>*/}
        {/*        <div className={""}><DownArrow/></div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<AnimatePresence>*/}
        {/*    {isOpen&&<motion.div className={"border-[2px] rounded-[28px] border-wine-300 p-2 bg-white absolute w-full z-40"}*/}
        {/*                         initial={{ opacity: 0 }}*/}
        {/*                         transition={{ type: "spring", stiffness: 100 }}*/}
        {/*                         animate={{ opacity: 1 }}*/}
        {/*                         exit={{ opacity: 0 }}*/}
        {/*    >*/}
        {/*        {tagsList.map(el=> <div className={"text-wine-500 cursor-pointer px-1 my-2 hover:bg-wine-500 hover:text-white rounded-2xl transition-all"} key={el}*/}
        {/*                                onClick={()=>{*/}
        {/*                                    if(value==="categories"){*/}
        {/*                                        changeSearchCategories(el)*/}
        {/*                                        setIsOpen(false)*/}
        {/*                                    }else{*/}
        {/*                                        changeSearchRegion(el)*/}
        {/*                                        setIsOpen(false)*/}
        {/*                                    }*/}
        {/*                                }}*/}
        {/*        >*/}
        {/*            <span className={`border-b-[2px] ${(value==="categories")?(el===searchCategories?"border-b-[2px] border-rose-400/50":'border-wine-400/50'):(el===searchRegion?'border-b-[2px] border-rose-400/50':'border-wine-400/50')}`}>*/}
        {/*            {el}*/}
        {/*            </span>*/}
        {/*        </div>)}*/}
        {/*    </motion.div>}*/}
        {/*</AnimatePresence>*/}

        {/*{isOpen&&<div className={"w-screen h-screen fixed top-0 left-0 z-30"} onClick={() => {*/}
        {/*    setIsOpen(false)*/}
        {/*}}></div>}*/}

    </div>


}

export default SearchTagsContainer