import {createContext, FC, ReactNode, useEffect, useState} from "react";
import {uiContentText} from "./uiContentText";
import useLocalStorage from "../hooks/useLocalStorage";
import {useAtom} from "jotai";

interface LanguageCtxInterface {
    uiText:typeof uiContentText.zh;
    toggleLanguage:()=>void;
    isEn:boolean;
}

const LanguageContext = createContext<LanguageCtxInterface>({
    uiText:{...uiContentText.zh},
    toggleLanguage:()=>{},
    isEn:false,
});


export const LanguageContextProvider:FC<{children: ReactNode}>=({children}) => {

    const [isEn,setIsEn] = useLocalStorage('isEn',false)
    const [uiText, setUiText] = useState(isEn?{...uiContentText.en}:{...uiContentText.zh});
    const toggleLanguage=()=>{
        setIsEn(!isEn)
    }
    useEffect(()=>{
        if(isEn){
            setUiText({...uiContentText.en})
        }else {
            setUiText({...uiContentText.zh})
        }
    },[isEn])

    return <LanguageContext.Provider value={{
        uiText,
        toggleLanguage,
        isEn
    }}>
        {children}
    </LanguageContext.Provider>
};

export default LanguageContext;