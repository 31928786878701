import {createContext, FC, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {formatExhibit, formatMuseum, getCategories, getDistricts, getExhibit, getMuseums} from "../utils/getData";
import {useInView} from "react-cool-inview";
import {exhibitDataType, museumDataType, SearchCtxInterface} from "../types/dataType";
import LanguageCtx from "./LanguageCtx";
import {shuffleArray} from "../utils/arrayControl";
import scrollToId from "../utils/scrollToId";

const SearchContext = createContext<SearchCtxInterface>({
    searchCategories:'',
    searchRegion:'',
    changeSearchCategories:()=>{},
    changeSearchRegion:()=>{},
    categories:[],
    categoriesTags:[],
    categoriesTagsColor:[],
    districts:[],
    searchHistory:[],
    deleteSearchHistory:()=>{},
    sortTagText:'',
    changeSortTagText:()=>{},
    exhibits:[],
    museums:[],
    originalMuseums:[],
    originalExhibits:[],
    searchBarRef:'',
    searchBarInView:true,
    language:'',
    toggleLanguage:()=>{},
    // category:'', updateCategory:()=>{}, district:'', updateDistrict:()=>{},
    // selectedMuseum:undefined, updateSelectedMuseum:()=>{},
    // selectedExhibit:undefined, updateSelectedExhibit:()=>{},
    isResultShowExhibit:true, checkResultExhibit:(a)=>{},
    searchKeyword:'', updateSearchKeyword:()=>{},
    searchEnter:()=>{},
    // fadeMuseum:undefined, updateFadeMuseum:()=>{},
    // fadeExhibit:undefined, updateFadeExhibit:()=>{},
    showSearchPage:true,changeSearchPage:()=>{},
    topPointHandler:()=>{},
    lastSelectedUpdate:'',
    errorMessage:{title:'',content:''},updateErrorMessage:()=>{},
});


export const SearchContextProvider:FC<{children: ReactNode}>=({children}) => {
    const {uiText, toggleLanguage, isEn}=useContext(LanguageCtx)

    const [language,setLanguage]=useState(isEn?'en':'zh-Hant-TW')
    const [showSearchPage,setShowSearchPage]=useState(true)
    const changeSearchPage=(a:boolean)=>{setShowSearchPage(a)}
    useEffect(()=>{
        if(showSearchPage){
            document.body.style.overflow = 'auto'
        }else{
            document.body.style.overflow = 'hidden'
        }
    },[showSearchPage])

    // 用戶選取的搜尋關鍵字
    const [searchCategories, setSearchCategories] = useState(uiText.search.categoryTitle);
    const changeSearchCategories=(s:string)=>{setSearchCategories(s)}
    const [searchRegion, setSearchRegion] = useState(uiText.search.districtTitle);
    const changeSearchRegion=(s:string)=>{setSearchRegion(s)}
    const [sortTagText,setSortTagText]=useState('最精準')
    const changeSortTagText=(s:string)=>{setSortTagText(s)}



    const [searchHistory, setSearchHistory]=useState<string[]>([])
    useEffect(()=>{
        if(localStorage.getItem('searchHistory')!==null && localStorage.getItem('searchHistory')!=='') {
            setSearchHistory(localStorage.getItem('searchHistory')!.split(','))
        }
    },[])

    const deleteSearchHistory=(delSearch:string)=>{
        setSearchHistory(prev=>{
            const newHistoryList = [...prev.filter(el=>el!==delSearch)]
            if(newHistoryList.length===1 && delSearch===''){
                localStorage.removeItem('searchHistory')
            }else{
                localStorage.setItem('searchHistory',newHistoryList.toString())
            }
            return newHistoryList
        })
    }
    const addSearchHistory=(newSearch:string)=>{
        if(newSearch !== ''){
            setSearchHistory(prev=>{
                let newHistoryList = []
                if(!prev.includes(searchKeyword)){
                    newHistoryList = prev.length===5 ? [newSearch, ...prev.splice(4, 1)] : [newSearch, ...prev]
                }else{
                    newHistoryList = [searchKeyword,...prev.filter(el=>el!==searchKeyword)]
                }
                localStorage.setItem('searchHistory',newHistoryList.toString())
                return newHistoryList
            })
        }
    }
    const [shouldRenewSearchHistory,setShouldRenewSearchHistory]=useState(false)
    useEffect(()=>{
        if(shouldRenewSearchHistory){
            addSearchHistory(searchKeyword)
            setShouldRenewSearchHistory(false)
        }
    },[shouldRenewSearchHistory])

    // 搜尋關鍵字設定
    const [categories,setCategories]=useState(['']) // 所有型清單
    const [categoriesTags,setCategoriesTags]=useState(['']) // 要顯示的推薦
    const [categoriesTagsColor,setCategoriesTagsColor]=useState(['bdccd4','bed3cf','c5dbe2','b9dadb','bfbed3'])
    const [allCategoriesTags,setAllCategoriesTags]=useState([{name:uiText.search.categoryTitle,tags:['']}])
    const [districts,setDistricts]=useState([uiText.search.districtTitle]) // 所有地區清單
    const [exhibits,setExhibits]=useState<exhibitDataType[]>([]) // 展覽 資料清單
    const [originalExhibits,setOriginalExhibits]=useState<exhibitDataType[]>([]) // 原始展覽 資料清單
    const [museums,setMuseums]=useState<museumDataType[]>([]) // 博物館 資料清單
    const [originalMuseums,setOriginalMuseums]=useState<museumDataType[]>([])

    const [searchKeyword, setSearchKeyword]=useState('')
    const updateSearchKeyword=(a:string)=>{
        setSearchKeyword(a)
    }
    const [isResultShowExhibit, setIsResultShowExhibit]=useState(true)
    const checkResultExhibit=(value:boolean)=>{
        setIsResultShowExhibit(value)
    }

    const [errorMessage,setErrorMessage]=useState({title:'',content:''})
    const updateErrorMessage=(a:{title:string,content:string})=>{setErrorMessage(a)}



    useEffect(()=>{
        getCategories(language).then((res:any)=>{
            try{
                let cateTags_all = ['劇情故事','平面藝術','科技','教育','場域導覽']
                if(isEn){
                    cateTags_all=['history', 'art season', 'collection', 'game']
                }
                setCategories([uiText.search.categoryTitle,...res.map((el:any)=>el.name)])
                setCategoriesTags(cateTags_all)
                setAllCategoriesTags([
                    {name:uiText.search.categoryTitle,tags:cateTags_all}
                    ,...res.map((el:any)=>({name:el.name,tags:el.tags}))
                ])
                setSearchCategories(uiText.search.categoryTitle)
            }catch (e) {
                console.log(e)
            }
        })
        getDistricts(language).then((res:any)=>{setDistricts([uiText.search.districtTitle,...res])})
        getExhibit(language).then((res:any)=>{
            if(res) {
                const exhibitsData:exhibitDataType[] = []
                res.forEach((el:any)=>{
                    exhibitsData.push(formatExhibit(el))
                })
                setExhibits(exhibitsData)
                setOriginalExhibits(exhibitsData)
            }else{
                setErrorMessage({title:'資料取得錯誤',content:"暫時無法取得資料，請稍後再試。"})
            }
        })
        getMuseums(language).then((res:any)=>{
            if(res) {
                const museumsData:museumDataType[] = []
                res.forEach((el:any)=>{
                    museumsData.push(formatMuseum(el))
                })
                setMuseums(museumsData.filter(el=>el.showInSearch))
                setOriginalMuseums(museumsData)
            }else{
                setErrorMessage({title:'資料取得錯誤',content:"暫時無法取得資料，請稍後再試。"})
            }
        })

    },[language,isEn])

    // const {lan} = useParams()
    // useEffect(()=>{
    //     if(lan&&lan==='en'){
    //         setLanguage('en')
    //     }else{
    //         setLanguage('zh-Hant-TW')
    //     }
    // },[lan])


    useEffect(()=>{
        if(isEn){
            setLanguage('en')
        }else{
            setLanguage('zh-Hant-TW')
        }
    },[isEn])



    const updateDataByCategory=()=>{
        if(searchCategories===uiText.search.categoryTitle){
            setExhibits(originalExhibits)
            setMuseums(originalMuseums.filter(el=>el.showInSearch))
        }else{
            setExhibits(prev=>originalExhibits.filter((el:any)=> {
                return el.exhibitCategories.includes(searchCategories)
            }))
            setMuseums(prev=>originalMuseums.filter(el=>el.showInSearch).filter((el:any)=> {
                return el.categoriesList.includes(searchCategories)
            }))
        }
    }
    // 搜尋結果篩選(最優先)
    useEffect(()=>{
        setCategoriesTags(allCategoriesTags.filter(el=>el.name===searchCategories)[0].tags)
        setCategoriesTagsColor(prev=>shuffleArray(prev))
        updateDataByCategory()
        searchEnter()
    },[searchCategories])

    const updateDataByKeyword=(keyword:string, addHistory:boolean=true)=>{
        if(keyword!==''){
            setExhibits(prev => {
                const filterExhibits = prev.filter((el: any) => el.tagsList.includes(keyword)
                    || el.exhibitName.includes(keyword)
                    || el.exhibitDescription.includes(keyword)
                )
                if (addHistory && filterExhibits.length > 0) {
                    setShouldRenewSearchHistory(true)
                }
                return filterExhibits
            })
            setMuseums(prev => {
                const filterMuseums = prev.filter((el: any) => {
                    return el.tagsList.includes(searchKeyword)
                        || (el.categoriesList.includes(keyword))
                        || (el.name.includes(keyword))
                        || (el.description.includes(keyword))
                })
                if (addHistory && filterMuseums.length > 0) {
                    setShouldRenewSearchHistory(true)
                }
                return filterMuseums
            })
        }
    }

    const [wouldEnter,setWouldEnter]=useState(false)
    const [enterString,setEnterString]=useState('')


    useEffect(()=>{
        if(wouldEnter){
            updateDataByCategory()
            if(enterString===''){
                searchKeyword.split(' ').forEach((el,idx,array) => {
                    if(idx === array.length -1 ){
                        updateDataByKeyword(el)
                    }else{
                        updateDataByKeyword(el,false)
                    }
                })
            }else{
                updateDataByKeyword(enterString)
            }
            setWouldEnter(false)
        }
    },[wouldEnter])

    const searchEnter=(inputString:string='')=> {
        setWouldEnter(true)
        setEnterString(inputString)
    }

    const { observe:searchBarRef, inView:searchBarInView } = useInView({
        rootMargin: '60px 0px 0px 0px',
        threshold: [1],
    })

    const topPointHandler=()=>{
        scrollToId('topPoint',500)
    }

    const [lastSelectedUpdate,setLastSelectedUpdate]=useState('')
    const updateLastSelectedUpdate=(input:any,preText:string,selfText:string)=>{
        if(input===undefined){
            setLastSelectedUpdate(preText)
        }else{
            setLastSelectedUpdate(selfText)
        }
    }

    // const [fadeMuseum, setFadedMuseum]=useState<museumDataType|undefined>()
    // const updateFadeMuseum=(museumData:museumDataType|undefined)=>{
    //     setFadedMuseum(museumData)
    // }
    // const [fadeExhibit, setFadeExhibit]=useState<exhibitDataType|undefined>()
    // const updateFadeExhibit=(exhibitData:exhibitDataType|undefined)=>{
    //     setFadeExhibit(exhibitData)
    // }
    //
    // const [selectedMuseum, setSelectedMuseum]=useState<museumDataType|undefined>()
    // const updateSelectedMuseum=(museumData:museumDataType|undefined)=>{
    //     setSelectedMuseum(museumData)
    // }
    // const [selectedExhibit, setSelectedExhibit]=useState<exhibitDataType|undefined>()
    // const updateSelectedExhibit=(exhibitData:exhibitDataType|undefined)=>{
    //     setSelectedExhibit(exhibitData)
    // }

    return <SearchContext.Provider value={{
        searchCategories,
        searchRegion,
        changeSearchCategories,
        changeSearchRegion,
        categories,
        categoriesTags,
        categoriesTagsColor,
        districts,
        searchHistory,
        deleteSearchHistory,
        sortTagText, changeSortTagText,
        exhibits,
        museums,
        originalMuseums,originalExhibits,
        searchBarRef, searchBarInView,
        language, toggleLanguage,
        // category, updateCategory, district, updateDistrict,
        // selectedMuseum, updateSelectedMuseum,
        // selectedExhibit, updateSelectedExhibit,
        isResultShowExhibit, checkResultExhibit,
        searchKeyword, updateSearchKeyword,
        searchEnter,
        // fadeMuseum, updateFadeMuseum,
        // fadeExhibit, updateFadeExhibit,
        showSearchPage,changeSearchPage,
        topPointHandler,
        lastSelectedUpdate,
        errorMessage,updateErrorMessage,
    }}>
        {children}
    </SearchContext.Provider>
};

export default SearchContext;