export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function randomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export const staggeredLengthArray =(array)=> {
    const array1 = array.sort((a,b)=>{
        if(a.name.length>b.name.length){
            return 1
        }else{
            return -1
        }
    })

    const array2 = array.concat().reverse()
    const resultArray = array1.reduce((acc, curr, idx) => {
        acc.push(curr, array2[idx]);
        return acc;
    }, [])

    return resultArray.slice(0,array.length).reverse()
}

export const randomSelection=(arr, count)=> {
    let result = [];
    let array = [...arr];

    while (count-- && array.length) {
        let index = Math.floor(Math.random() * array.length);
        result.push(array.splice(index, 1)[0]);
    }
    return result;
}