export const hideDownProps = {
    transition: {
        type: "spring",
        stiffness: 100,
    },
    initial: {opacity: 0},
    animate: {opacity: 1, translateY:0},
    exit: {opacity: 0, translateY: 100},
}

export const fadeProps = {
    transition: {type: "spring", stiffness: 100,duration:1},
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
}

export const popProps = {
    transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
    },
    initial: {scale: 0},
    animate: {scale: 1},
    exit: {scale: 0},
};

export const spinProps = {
    initial: {rotate: 0},
    animate: {
        rotate: 360
    },
    transition: {
        type: "tween", // inertia 慣性, spring 彈, tween 補間
        duration: 3,
        // times:[0.75,1.5,0.75],
        repeat: Infinity,
        repeatDelay: 0.5
    }
}

export const houseTagsContainer = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.5,
            staggerChildren: 0.3,
            ease: "anticipate"
        }
    }
}

export const houseTagsItem = {
    hidden: {
        opacity: 0,
        y: -300
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            // ease: "circInOut"
            // ease: "backInOut"
            // ease: [0.17, 0.67, 0.83, 0.67]
            type:"spring"
        }
    }
}

export const scrollArrowProps = {
    initial:{
        clipPath: 'inset(0 0 100% 0)',
        bottom:'-10%',
    },
    animate:{
        clipPath:['inset(0 0 100% 0)','inset(0 0 0% 0)','inset(0 0 0% 0)','inset(100% 0 0% 0)'],
        translateY:['-10%','0%','0%','10%'],
    },
    transition:{
        times:[0,0.4,0.6,1],
        duration:2,
        repeat: Infinity,
        repeatDelay: 0,
    }
}

export const scrollTextProps = {
    initial:{
        opacity:1
    },
    animate:{
        opacity:[0.3,1,1,0.3],
    },
    transition:{
        times:[0,0.3,0.5,1],
        duration:2,
        repeat: Infinity,
        repeatDelay: 0,
    }
}
