import React, {useContext, useEffect, useRef, useState} from 'react';
import IntroCard from "./IntroCard";
import {Outlet, Link, useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {cardState} from "../../store/ValtioState";

const ExhibitIntro:React.FC<{
    key?:string;
    value:any;
}> = ({value}) => {
    const {selectedExhibit} = useSnapshot(cardState) as typeof cardState
    const {id,mainMedia, exhibitName,showTagsList, organizersList} = value

    const [isOpen,setIsOpen] =useState(false)

    const navigate = useNavigate()
    const clickHandler=()=>{
        // updateSelectedExhibit(value)
        navigate(`exhibits/${id}`)
        // navigate(`exhibits/${exhibitName}`)

    }

    useEffect(()=>{
        if(selectedExhibit&&selectedExhibit.exhibitName===exhibitName){
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }
    },[selectedExhibit])

    return <IntroCard
        title={exhibitName}
        picSrc={mainMedia}
        subTitleList={organizersList.map((el:any)=>el.name)}
        tagList={showTagsList}
        onClick={clickHandler}
        isSelected={isOpen}
        idHook={`exhibit${value.id}`}
    />
}

export default ExhibitIntro
