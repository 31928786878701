export const uiContentText =  {
    zh:{
        "search": {
            "category": "類型",
            "district": "地區",
            "categoryTitle": "所有類型",
            "districtTitle": "所有地區",
            "mostRecent": "最近搜尋"
        },
        "result": {
            "title": "搜尋結果",
            "typeList": [
                "線上展覽",
                "博物館"
            ],
            "noData": "暫時沒有資料唷"
        },
        "detail": {
            "weekList": [
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
                "星期日"
            ],
            "closed": "閉館整建中",
            "readMore": "閱讀更多",
            "unReadMore": "收起文章",
            "mayLike": "你可能會喜歡",
            "openHoursTitle": "開放時間",
            "openHoursSubTitle": "點擊後展開"
        },
        "menu": {
            "search": "博物館 / 展覽搜尋",
            "report": "問題回報",
            "animation": "開頭動畫",
            "about": "關於我們"
        },
        "about":{
            "content":"環耀實境成立於2016年，專注於擴增實境AR與虛擬實境VR的商業應用與解決方案，並注重展覽的觀展品質。\n\n我們的核心價值是「以洗鍊的設計包裝堅實的工程技術」，堅持品質至上並挑戰設計與技術的極限。\n我們的核心技術是環景攝影、電腦視覺、UI/UX、3D場景與動畫，且在三維模型的掃描與重建技術方面領先業界。\n我們也注重攝影、電腦視覺和藝術方面的細節，在網站介面設計上下足功夫，並能根據產品型態客製化製作令客戶滿意之作品。",
            "subTitle":"【以洗鍊的設計包裝堅實的工程技術】",
            "leaveUsAMessage":"留言給我們",
            "getToKnowUsBetter":"更認識我們"
        }
    },
    en:{
        "search":{
            "category":"Category",
            "district":"District",
            "categoryTitle":"All Category",
            "districtTitle":"All District",
            "mostRecent":"Recently Searched"
        },
        "result":{
            "title":"Search Result",
            "typeList":["Exhibition","Museum"],
            "noData":"NO DATA"
        },
        "detail":{
            "weekList":["MON","TUE","WED","THU","FRI","SAT","SUN"],
            "closed":"closed",
            "readMore":"read more",
            "unReadMore":"read less",
            "mayLike":"YOU MIGHT LIKE",
            "openHoursTitle":"OPENING HOURS",
            "openHoursSubTitle":"click to expand"
        },
        "menu":{
            "search":"Search",
            "report":"Report",
            "animation":"Opening",
            "about":"AboutUs"
        },
        "about":{
            "content":"Founded in 2016, Ambient Reality focuses on the business applications and solutions of augmented reality AR and virtual reality VR, and pays attention to the quality of exhibition viewing. Our core value is \"packaging solid engineering technology with chain-cleaning design\", insisting on quality first and challenging the limits of design and technology. Our core technologies are surround view photography, computer vision, UIUX, 3D scenes and animation, and we are leading the industry in 3D model scanning and reconstruction technology. We also pay attention to the details of photography, computer vision and art, and work hard on the website interface design, and can customize and produce works that satisfy customers according to the product type.",
            "subTitle":"【Solid engineering packed in chain-washing design】",
            "leaveUsAMessage":"COMMENTS",
            "getToKnowUsBetter":"KNOW US"
        }
    }
}