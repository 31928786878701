import {motion} from "framer-motion";

const ReportIcon=()=>{
    return <motion.svg className="w-full h-full pointer-events-none" style={{scale:2.5}} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 104">
        <path d="M173.52,35.14a3.94,3.94,0,0,0-1.22-1.22l-8.44-5.33V21.51a4,4,0,0,0-4-4H146.32l-7.74-4.89a4,4,0,0,0-4.23,0l-8,4.92H112.14a4,4,0,0,0-4,4v7.21l-8.4,5.17a4.06,4.06,0,0,0-1.26,1.25,4,4,0,0,0-1.44,3v40A8.85,8.85,0,0,0,105.88,87h60.24A8.85,8.85,0,0,0,175,78.17v-40A4,4,0,0,0,173.52,35.14ZM167,75.85,151.42,58.17,167,44.41ZM116.14,25.51h39.72V42.62h0a3.86,3.86,0,0,0,.1.84l-19.4,17.19-.11.1-.06,0L116.14,42.09V25.51ZM105,42.76,119,55.58a3,3,0,0,0-.85.66L105,71.15ZM122.65,60.2a2.83,2.83,0,0,0,.49-.81l7.78,7.15a7.91,7.91,0,0,0,11,0l5-4.42L161.74,79H106.13Z"/>
        <path d="M123.15,34h25.7a1.5,1.5,0,0,0,0-3h-25.7a1.5,1.5,0,1,0,0,3Z"/>
        <path d="M123.61,37.9a1.5,1.5,0,0,0,0,3h25.7a1.5,1.5,0,0,0,0-3Z"/>
    </motion.svg>
}

export default ReportIcon