import React, {FC, ReactNode} from 'react';

const Linkbutton:FC<{
    key?: string;
    url: any;
    children: ReactNode;
}> = (props) => {
    const {url, children} = props

    return <div
        className={`w-6 h-6 cursor-pointer ${url.length===0&&'hidden'} transition-transform hover:scale-125`}
        onClick={()=>{window.open(url)}}
    >
        {children}
    </div>
}

export default Linkbutton