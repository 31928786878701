import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SearchContextProvider} from "./store/SearchCtx";
import {LanguageContextProvider} from "./store/LanguageCtx";
import {IconContext} from "react-icons"

import ProjectRouter from "./routes/ProjectRouter";
import {Provider} from "jotai";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <IconContext.Provider value={{style: {verticalAlign: 'middle',width:'100%',height:'100%'}}}>
            <LanguageContextProvider>
                <SearchContextProvider>
                    <Provider>
                        <ProjectRouter/>
                    </Provider>
                </SearchContextProvider>
            </LanguageContextProvider>
        </IconContext.Provider>
    </React.StrictMode>
);
reportWebVitals();
