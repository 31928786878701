import React, {useContext, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion, useMotionValue, useSpring, useTransform} from "framer-motion";
import searchCtx from "../../store/SearchCtx";
import {scrollToId, slideGoLeft, slideGoRight} from "../../utils/slide";
import ReactMarkdown from 'react-markdown'
import Carousel from "./Carousel";
import {exhibitDataType, museumDataType, organizer} from "../../types/dataType";
import LanguageCtx from "../../store/LanguageCtx";
import CardTagList from "./CardTagList";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {randomSelection} from "../../utils/arrayControl";
import CategoryTags from "../ResultSection/CategoryTags";
import {fadeProps} from "../../utils/animationType";

const isMd=()=>document.body.clientWidth>1024

const ShowExhibitCard:React.FC<{
    exhibitData:exhibitDataType|undefined;
    wouldCloseSelf?:boolean;
    layoutName:string;
    childLayoutName: string;
}>=({exhibitData,layoutName,childLayoutName,wouldCloseSelf=false})=>{
    const { uiText, isEn } =useContext(LanguageCtx)
    const { museums, language,isResultShowExhibit,originalMuseums,exhibits }=useContext(searchCtx)
    const [imgSrcMain,setImgSrcMain] = useState('')
    const [showCard, setShowCard] =useState(false)
    const [picNameList,setPicNameList]=useState([])

    const [randomTags,setRandomTags]=useState<string[]>([])
    const [sponsorMuseum,setSponsorMuseum]=useState<museumDataType[]|null>(null)
    useEffect(()=>{
        if(exhibitData){
            setSponsorMuseum(originalMuseums.filter((el:museumDataType)=> exhibitData.organizersList.map((el: organizer) => el.name).includes(el.name)))
            setRandomTags(randomSelection(exhibits.map((el:exhibitDataType)=>el.showTagsList).reduce((acc, cur) => acc.concat(cur), []),5))
        }
    },[exhibitData])





    const childLayout= (name:string, type:string) => childLayoutName==='no'?{}:{layoutId:`${childLayoutName}${name}${type}`}

    const showContentAnimation = {
        initial:{opacity:0}, animate:{opacity:1}, transition:{ ease: "easeOut", duration: 0.2 ,delay:0.3}
    }

    const {exhibitId,museumId,exhibitId2,museumId2} = useParams()
    const navigate = useNavigate()

    useEffect(()=>{
        if(exhibitData){
            setShowCard(true)
            setImgSrcMain(exhibitData.mainMedia)
        }else{
            setShowCard(false)
        }
    },[exhibitData])

    return <>
        <AnimatePresence>
            {exhibitData &&　<motion.div
                layoutId={`${layoutName}${exhibitData.exhibitName}Container`}
                className={`pt-16 w-full h-full fixed overflow-y-scroll bg-white z-[54] bottom-0 pointer-event-auto`}
                transition={{ type: "spring", duration: 0.8 }}
            >
                {exhibitData.hasInteractive && <div className="absolute w-16 h-16 z-20 flex justify-center items-center">
                    <motion.img
                        src="./images/360.png" className="drop-shadow-lg w-[80%] drop-shadow-black translate-y-0.5"/>
                </div>}

                <div className={"flex flex-col items-center text-wine-500"}>

                    <Carousel exhibitData={exhibitData} layoutName={layoutName}/>

                    <div className={"px-[10vw] lg:pl-[10vw] py-2 md:py-3 lg:py-10 lg:w-[80vw] max-w-[80rem]"}>

                        <div className="flex flex-col  gap-4  mb-2">
                            <motion.div
                                // layoutId={`${layoutName}${exhibitData.exhibitName}Title`}
                                className={"font-serif text-2xl font-bold text-left"}
                            >{exhibitData.exhibitName}</motion.div>
                            <CardTagList
                                name={exhibitData.exhibitName}
                                tagList={exhibitData.showTagsList}
                                isHide={!showCard}
                                layoutName={layoutName}
                            />
                        </div>

                        <motion.div className={"border-y-[2px] border-wine-200 py-8 mt-3"}  {...showContentAnimation}>
                            <div className={"flex flex-row mb-8 gap-4"}>
                                {sponsorMuseum&&sponsorMuseum.map((el:museumDataType)=>{
                                    const imgUrl = el.mainMedia
                                    const img2Url = el.hoverMedia
                                    const name = el.name
                                    const phone = el.phoneNo
                                    return <motion.div
                                        {...childLayout(el.name,'Container')}
                                        // layoutId={`${childLayoutName}${name}Container`}
                                        key={name}
                                        className="group relative rounded-2xl border border-1 text-left relative cursor-pointer hover:shadow-lg "
                                        onClick={()=>{
                                            // updateChild(el)
                                            navigate(`/exhibits/${exhibitId}/${el.id}`)
                                            // navigate(`/exhibits/${exhibitId}/${el.name}`)
                                            if(wouldCloseSelf){
                                                // updateSelf(undefined)
                                                navigate(`/museums/${el.id}`)
                                                // navigate(`/museums/${el.name}`)
                                            }
                                        }}
                                    >
                                        <div className={"flex items-center justify-center opacity-0 transition-opacity group-hover:opacity-100 rounded-2xl w-full h-full absolute bg-wine-500/50"}>
                                            <div className="text-white font-light text-center p-4">{name}</div>
                                        </div>
                                        <motion.img
                                            {...childLayout(el.name,'Image')}
                                            // layoutId={`${childLayoutName}${name}Image`}
                                            className={"rounded-2xl w-36 md:w-44"}
                                            src={imgUrl}
                                        />
                                    </motion.div>
                                })}
                            </div>
                            <div className={`ReactMarkdown text-lg text-left leading-7  ${isEn?'text-left':'text-justify tracking-[0.25em]'}`}>
                                <ReactMarkdown>
                                    {exhibitData.exhibitDescription}
                                </ReactMarkdown>
                            </div>


                        </motion.div>
                        <motion.div className="w-full pt-4" {...fadeProps}>
                            <div className="text-xl text-left">{uiText.detail.mayLike}</div>
                            <div className="-ml-[38px] mt-4">
                                <CategoryTags tagList={randomTags}/>
                            </div>
                        </motion.div>

                    </div>
                    <motion.div className={"rounded-full w-[202px] cursor-pointer fixed right-4 bottom-4 transition-opacity hover:opacity-90 animate-bounce "}
                                {...fadeProps}
                                onClick={()=>{
                                    window.open(exhibitData.exhibitURL)
                                }}>
                        <img src="./images/bus_wait.gif"
                             className="scale-[3] object-contain pointer-events-none translate-y-[38px]"/>
                        <img src="./images/icon-09.png" className={`pointer-events-none ${isEn?'hidden':'block'}`}/>
                        <img src="./images/icon-14.png" className={`pointer-events-none ${isEn?'block':'hidden'}`}/>
                    </motion.div>
                </div>


                <div className="h-40 w-screen"></div>
            </motion.div>}
        </AnimatePresence>

        {/*{showCard && <motion.div*/}
        {/*    className={"w-screen h-screen fixed top-0 left-0 z-[53]"}*/}
        {/*    initial={{ opacity: 0 }}*/}
        {/*    animate={{ opacity: 0 }}*/}
        {/*    exit={{ opacity: 0, transition: { duration: 0.15 } }}*/}
        {/*    transition={{ duration: 0.2, delay: 0.15 }}*/}
        {/*    onClick={() => {*/}
        {/*        updateSelf(undefined)*/}
        {/*    }}*/}
        {/*/>}*/}
    </>
}
export default ShowExhibitCard