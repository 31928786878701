import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useContext} from "react";
import searchCtx from "../store/SearchCtx";
import {useAtom} from "jotai";
import {isOpenAboutPageAtom} from "../components/About";
import {cardState} from "../store/ValtioState";

const useCardControl=()=>{
    const { changeSearchPage, topPointHandler }=useContext(searchCtx)
    const navigate = useNavigate()
    const {exhibitId,museumId,exhibitId2,museumId2} = useParams()
    const [isOpenAboutPage,setIsOpenAboutPage] = useAtom(isOpenAboutPageAtom)

    const searchIconHandler=()=>{
        setIsOpenAboutPage(false)
        changeSearchPage(true)
        closeAllCard()
    }

    const goHomeAndTop=()=>{
        navigate(`/`)
        // cardState.selectedExhibit = undefined
        // cardState.selectedMuseum = undefined
        // cardState.fadeMuseum = undefined
        // cardState.fadeMuseum = undefined
        topPointHandler()
    }

    let closeCardTimer = setTimeout(()=>{})
    const closeAllCard=()=>{
        if(museumId2){
            navigate(`/exhibits/${exhibitId}`)
            clearTimeout(closeCardTimer)
            setTimeout(()=>{goHomeAndTop()},500)
        }else if(exhibitId2){
            navigate(`/museums/${museumId}`)
            clearTimeout(closeCardTimer)
            setTimeout(()=>{goHomeAndTop()},500)
        }else{
            goHomeAndTop()
        }
    }

    const closeOneCard=()=>{
        if(museumId2){
            navigate(`/exhibits/${exhibitId}`)
        }else if(exhibitId2){
            navigate(`/museums/${museumId}`)
        }else if(museumId||exhibitId){
            navigate(`/`)
        }
    }
    return {searchIconHandler, closeOneCard, closeAllCard}
}

export default useCardControl