import React, {FC, useContext} from 'react';
import SearchBar from "./SearchBar";
import SearchTagsContainer from "./SearchTagsContainer";
import searchCtx from "../../store/SearchCtx";
import {motion, AnimatePresence} from "framer-motion";
import {hideDownProps} from "../../utils/animationType";
import useWindowSize from "../../hooks/useWindowSize";

const SearchSection:FC<{

}> = () => {
    const {categories, districts,showSearchPage}=useContext(searchCtx)
    const {width:windowWidht, height:windowHeight} = useWindowSize()

    return <div className={`mt-8 md:mt-16 w-full flex flex-col items-center ${!showSearchPage&&windowHeight<500&&'opacity-40 pointer-events-none select-none blur-sm'}`}>
        <SearchBar/>
        {/*TagBarItem*/}
        {/*<AnimatePresence>{showSearchPage&&(*/}
        {/*    <motion.div className={"flex flex-col items-center"} {...hideDownProps}>*/}

            <motion.div className={`flex flex-col items-center transition-opacity duration-500 ${!showSearchPage&&'opacity-40 pointer-events-none select-none blur-sm'}`}>
                <div
                    className={"flex lg:flex-col justify-around lg:justify-start mx-auto px-4 gap-6 md:gap-10 lg:gap-0"}>
                    <SearchTagsContainer value={"categories"} tagsList={categories}/>
                    {/*<SearchTagsContainer value={"region"} tagsList={districts}/>*/}
                </div>
            </motion.div>

        {/*)}</AnimatePresence>*/}
    </div>
}

export default SearchSection