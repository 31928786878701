import React, {useContext, useEffect, useState} from "react";
import searchCtx from "../store/SearchCtx";

const TagBarItem:React.FC<{key?:string;className?:string;value:string;type:string;}>=({className,value,type})=> {
    const { searchCategories, searchRegion, changeSearchCategories, changeSearchRegion,updateSearchKeyword}=useContext(searchCtx)

    const [isSelected,setIsSelected]=useState(false)

    const updateIsSelected=(search:string)=>{
        if(search===value){
            setIsSelected(true)
        }else{
            setIsSelected(false)
        }
    }

    useEffect(()=>{
        if(type==='region'){
            updateIsSelected(searchRegion)
        }else if(type==="categories"){
            updateIsSelected(searchCategories)
        }
    },[searchCategories,searchRegion])

    return <div className={`cursor-pointer ${className && className}`}
                onClick={()=>{
                    if(type==='region'){
                        changeSearchRegion(value)
                    }else if(type==="categories"){
                        changeSearchCategories(value)
                    }
                }}
    >
        <div
        className={`py-2 px-4 m-1 h-8 whitespace-nowrap flex justify-center items-center text-sm lg:text-md
        ${isSelected?'text-white bg-wine-600 rounded-md':'text-wine-400 hover:text-wine-700 '}
        `}
    >
            {value}
        </div>
    </div>
}
export default TagBarItem