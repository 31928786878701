import React, {useContext, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion, useMotionValue, useSpring, useTransform} from "framer-motion";
import searchCtx from "../../store/SearchCtx";
import {scrollToId, slideGoLeft, slideGoRight} from "../../utils/slide";
import ReactMarkdown from 'react-markdown'
import Carousel from "./Carousel";
import CardTagList from "./CardTagList";
import Twitter from "../icons/Twitter";
import Facebook from "../icons/Facebook";
import Instagram from "../icons/Instagram";
import Youtube from "../icons/Youtube";
import Linkbutton from "./LinkButton";
import LanguageCtx from "../../store/LanguageCtx";
import CloseButton from "../icons/CloseButton";
import {exhibitDataType, museumDataType} from "../../types/dataType";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {BsFillTelephoneFill} from "react-icons/bs";
import {AiFillHome, AiFillInstagram} from "react-icons/ai";
import {RiInstagramFill,RiFacebookCircleFill,RiYoutubeFill,RiHome2Fill,RiTwitterFill,RiPhoneFill} from "react-icons/ri";
import {useSnapshot} from "valtio";
import {cardState} from "../../store/ValtioState";

const isMd=()=>document.body.clientWidth>1024

const ShowMuseumCard:React.FC<{
    museumData: museumDataType|undefined;
    layoutName: string;
    childLayoutName: string;
    wouldCloseSelf?: boolean;
}>=({museumData,layoutName,childLayoutName,wouldCloseSelf=false})=>{
    const {fadeExhibit} = useSnapshot(cardState) as typeof cardState
    const { exhibits }=useContext(searchCtx)
    const { isEn, uiText } =useContext(LanguageCtx)
    const [showCard, setShowCard] =useState(false)
    const [isShowFullDescription, setIsShowFullDescription]=useState(false)

    const [isShowingExhibit, setIsShowingExhibit]=useState('')
    useEffect(()=>{
        if(fadeExhibit === undefined){
            setIsShowingExhibit('')
        }
    },[fadeExhibit])
    const [isTimeOpen,setIsTimeOpen]=useState(false)

    useEffect(()=>{
        setIsTimeOpen(false)
    },[museumData])

    const showContentAnimation = {
        initial:{opacity:0}, animate:{opacity:1}, transition:{ ease: "easeOut", duration: 0.5 ,delay:0.5}
    }

    const childLayout= (name:string, type:string) => childLayoutName==='no'?{}:{layoutId:`${childLayoutName}${name}${type}`}

    const {exhibitId,museumId,exhibitId2,museumId2} = useParams()
    const navigate = useNavigate()

    useEffect(()=>{
        if(museumData){
            setShowCard(true)
        }else{
            setShowCard(false)
            setIsShowFullDescription(false)
        }
    },[museumData])

    const [isLarge,setIsLarge]=useState(false)
    useEffect(() => {
        function handleResize() {
            if(window.innerWidth>1024){
                setIsLarge(true)
            }else{
                setIsLarge(false)
            }
        }
        window.addEventListener('resize', handleResize)
    })


    let week = -1

    return <>
        <AnimatePresence>
            { museumData &&　<motion.div
                layoutId={`${layoutName}${museumData.name}Container`}
                className={`pt-16 w-full h-full fixed overflow-y-scroll lg:overflow-hidden bg-white z-[54] bottom-0 pointer-event-auto`}
                transition={{ type: "spring", duration: 0.8 }}
            >
                <div className="flex flex-col items-center lg:items-start lg:justify-center lg:flex-row h-full ">
                    <div className="lg:w-1/3 lg:h-full relative">
                        {museumData.mediaList.length>0 && <motion.img
                                    // layoutId={`${layoutName}${museumData.name}Image`}
                                    src={isLarge?museumData.mediaList[0].url:museumData.mediaList[1].url}
                                    className="w-full h-[30vh] lg:h-full object-cover lg:absolute"
                                />}
                        <div className="hidden lg:flex flex-col h-full justify-between items-center px-0">
                            <motion.div
                                className="text-xl px-0 lg:px-10 py-6  font-serif text-3xl text-wine-500 lg:text-white font-bold lg:drop-shadow-strong lg:w-full text-left"
                                // layoutId={`${layoutName}${museumData.name}Title`}
                            >
                                {museumData.name}
                            </motion.div>

                        </div>
                    </div>
                    <div className="lg:overflow-y-scroll h-full">
                        <div className="flex-1 max-w-[800px] px-6 md:px-20 py-4 flex flex-col -mt-10 lg:mt-4 ">
                            <div>
                                <motion.div
                                    className="lg:hidden mt-10 text-left"><motion.div
                                    className="text-xl px-0 lg:px-10 py-6  font-serif text-3xl text-wine-500 lg:text-white font-bold lg:drop-shadow-strong lg:w-full text-left"
                                    // layoutId={`${layoutName}${museumData.name}Title`}
                                >
                                    {museumData.name}
                                </motion.div></motion.div>
                                <div className="relative w-72 mb-4">
                                    {museumData.customBannerList.map(el=> {
                                        return <motion.img src={el} key={el} className="last:animate-shine2 first:absolute first:animate-shine"/>
                                    })}
                                </div>
                                <CardTagList name={museumData.name} layoutName={layoutName} tagList={[museumData.district,...museumData.showTagsList,...museumData.categoriesList]} isHide={!showCard}/>
                                {/*<motion.div*/}
                                {/*    {...showContentAnimation}*/}
                                {/*    className="flex flex-row-reverse text-tag-text gap-4 justify-end mt-10"*/}
                                {/*>*/}
                                {/*    <Linkbutton url={museumData.twitterURL}><Twitter/></Linkbutton>*/}
                                {/*    <Linkbutton url={museumData.fbURL}><Facebook/></Linkbutton>*/}
                                {/*    <Linkbutton url={museumData.IGURL}><Instagram/></Linkbutton>*/}
                                {/*    <Linkbutton url={museumData.youtubeURL}><Youtube/></Linkbutton>*/}
                                {/*    <Linkbutton url={museumData.websiteURL}><Bank/></Linkbutton>*/}
                                {/*</motion.div>*/}

                            </div>


                            <div className="my-10 ">
                                <div className={`ReactMarkdown text-lg text-left leading-7  ${isEn?'text-left':'text-justify tracking-[0.25em]'}`}>
                                    <ReactMarkdown>
                                        {museumData.description.split('\n')[0]}
                                    </ReactMarkdown>
                                    <motion.div className={`overflow-hidden -mt-[36px]`}
                                                initial={{height:0}}
                                                animate={isShowFullDescription?{height:'auto'}:{}}
                                    >
                                        <ReactMarkdown>
                                            {museumData.description.slice(museumData.description.split('\n')[0].length)}
                                        </ReactMarkdown>
                                    </motion.div>
                                </div>
                                <div className="text-right">
                                    {museumData.description.split('\n').length>1&&<span className="cursor-pointer text-blue-600 hover:text-blue-500"
                                           onClick={() => {
                                               setIsShowFullDescription(prev => !prev)
                                           }}
                                    >{isShowFullDescription ? uiText.detail.unReadMore : uiText.detail.readMore}</span>}
                                </div>
                            </div>



                            {/*<div className="bg-rose-200 w-20 rounded-full peer">開館時間</div>*/}

                            {/*星期幾資訊*/}

                            {/*<div className="border border-1 rounded-xl text-left px-6 py-4">*/}
                            {/*    營業時間*/}
                            {/*    {museumData.openningHourList.map(el=> {*/}
                            {/*        week += 1*/}
                            {/*        if(el.length===0){*/}
                            {/*            return <div key={`week${week}`}><span className="min-w-[56px] inline-block">{uiText.detail.weekList[week]}</span>{uiText.detail.closed}</div>*/}
                            {/*        }*/}
                            {/*        return <div key={`week${week}`}><span className="min-w-[56px] inline-block">{uiText.detail.weekList[week]}</span>{el}</div>*/}
                            {/*    })}*/}
                            {/*</div>*/}


                            <div className="divide-y sm:divide-x sm:divide-y-0 grid sm:grid-cols-2 items-center py-4 text-wine-500 font-bold border border-base-300 bg-transparent rounded-xl px-4 mb-4 mt-4">
                                <div className="flex flex-row-reverse items-center justify-center text-tag-text gap-12 sm:gap-4 mb-4 sm:mb-0">
                                    <Linkbutton url={museumData.twitterURL}><RiTwitterFill/></Linkbutton>
                                    <Linkbutton url={museumData.fbURL}><RiFacebookCircleFill/></Linkbutton>
                                    <Linkbutton url={museumData.IGURL}><RiInstagramFill/></Linkbutton>
                                    <Linkbutton url={museumData.youtubeURL}><RiYoutubeFill/></Linkbutton>
                                    <Linkbutton url={museumData.websiteURL}><AiFillHome/></Linkbutton>
                                </div>
                                <div className="flex justify-center gap-4 items-center pt-4 sm:pt-0">
                                    <Linkbutton url={`tel:${museumData.phoneNo}`}><RiPhoneFill/></Linkbutton>
                                    <a href={`tel:${museumData.phoneNo}`}>{museumData.phoneNo}</a>
                                </div>
                            </div>

                            <div className="collapse collapse-plus border border-base-300 bg-transparent rounded-xl px-4 hover:shadow-lg "

                            >
                                <input type="checkbox" className="peer " onClick={()=>{setIsTimeOpen(prev=>!prev)}}/>
                                <div className="collapse-title text-center text-wine-500 font-bold pr-1 after:translate-y-0.5">
                                    <div className="translate-y-0.5">
                                        {uiText.detail.openHoursTitle}<span className={`transition-opacity font-light text-wine-400 ml-1 ${!isTimeOpen?'inline-block':'hidden'}`}> / {uiText.detail.openHoursSubTitle}</span>
                                    </div>
                                </div>
                                <div className="collapse-content text-left grid text-center sm:grid-cols-2">
                                    {museumData.openningHourList.map(el=> {
                                        week += 1
                                        if(el.length===0||el===null){
                                            return <div key={`week${week}`} className="text-gray-500 flex justify-center last:mb-4">
                                                <span className="mr-4 min-w-[56px] inline-block text-wine-500 font-bold">{uiText.detail.weekList[week]}</span>
                                                <span className="w-[100px]">{uiText.detail.closed}</span>
                                            </div>
                                        }
                                        return <div key={`week${week}`} className="text-gray-500 flex justify-center  last:mb-4">
                                            <span className="mr-4 min-w-[56px] inline-block text-wine-500 font-bold">{uiText.detail.weekList[week]}</span>
                                            <span className="w-[100px]">{el}</span>
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="border-y-2 border-wine-200 my-4 flex flex-col gap-4 items-center py-4" >
                                {museumData.onlineExhibitsList.map(el=> {
                                    const targetEx = exhibits.find(ele => ele.exhibitName === el.exhibitName)
                                    if(targetEx){
                                        return <motion.div
                                            // layoutId={`${childLayoutName}${targetEx.exhibitName}Container`}
                                            {...childLayout(targetEx.exhibitName,'Container')}
                                            className={`flex flex-col sm:flex-row sm:w-full text-xl gap-2 border border-1 rounded-xl hover:shadow-lg transition-all cursor-pointer transition-opacity ${(targetEx.exhibitName !== isShowingExhibit)?'opacity-1':'opacity-0'}`}
                                            key={`${el.exhibitName}MuseumResult`}
                                            onClick={()=>{
                                                if(childLayoutName==='no'){
                                                    setIsShowingExhibit(targetEx.exhibitName)
                                                }
                                                // updateChild(targetEx)
                                                navigate(`/museums/${museumId}/${targetEx.id}`)
                                                // navigate(`/museums/${museumId}/${targetEx.exhibitName}`)
                                                if(wouldCloseSelf){
                                                    setIsShowingExhibit('')
                                                    // updateSelf(undefined)
                                                    navigate(`/exhibits/${targetEx.id}`)
                                                    // navigate(`/exhibits/${targetEx.exhibitName}`)
                                                }
                                            }}
                                        >
                                            <motion.img
                                                {...childLayout(targetEx.exhibitName,'Image')}
                                                // layoutId={`${childLayoutName}${targetEx.exhibitName}Image`}
                                                className="w-full object-cover rounded-t-xl sm:rounded-l-xl sm:rounded-tr-none  sm:w-1/3 lg:w-[40%] object-contain "
                                                src={targetEx.mainMedia} />
                                            <div className={` flex flex-col justify-center ${isEn?'text-left':'text-justify'} gap-2 p-8 pt-2 sm:p-2 sm:pr-4`}>
                                                <motion.div
                                                    {...childLayout(targetEx.exhibitName,'Title')}
                                                    // layoutId={`${childLayoutName}${targetEx.exhibitName}Title`}
                                                    className="font-bold text-wine-500 font-serif mb-2 sm:m-0"
                                                >
                                                    {targetEx.exhibitName}
                                                </motion.div>
                                                <div className={`ReactMarkdownNoP text-wine-400 text-base text-left leading-7  ${isEn?'text-left':'text-justify tracking-[0.25em]'}`}>
                                                    <ReactMarkdown>
                                                        {`${targetEx.exhibitDescription.replaceAll('　','').slice(0,40)}...`}
                                                    </ReactMarkdown>
                                                </div>
                                            </div>
                                        </motion.div>
                                    }
                                    return <>
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </motion.div>}
        </AnimatePresence>

        {/*{showCard && <motion.div*/}
        {/*    className={"w-screen h-screen fixed top-0 left-0 z-[53]"}*/}
        {/*    initial={{ opacity: 0 }}*/}
        {/*    animate={{ opacity: 0 }}*/}
        {/*    exit={{ opacity: 0, transition: { duration: 0.15 } }}*/}
        {/*    transition={{ duration: 0.2, delay: 0.15 }}*/}
        {/*    onClick={() => {*/}
        {/*        updateSelf(undefined)*/}
        {/*    }}*/}
        {/*/>}*/}
    </>
}
export default ShowMuseumCard