import React, {FC, useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {useInView} from "react-cool-inview";

const CarouselImg:FC<{
    key?: string;
    value: any;
    changeShowImageName: (a:string)=>void;
}> = (props) => {
    const {value, changeShowImageName} = props

    const { observe, inView } = useInView({
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
    })

    useEffect(()=>{
        if(inView){
            changeShowImageName(value.name)
        }
    },[inView])

    const [showSecondImg,setShowSecondImg]=useState(false)
    let timer = setTimeout(()=>{},1000)
    useEffect(()=>{
        timer = setTimeout(()=>{setShowSecondImg(true)},500)
        return ()=>{
            clearTimeout(timer)
        }
    },[])

    return <div
        key={`${value.name}Pic`}
        id={value.name}
        className={`carousel-item w-full h-[30vh] lg:h-[45vh] ${showSecondImg?'flex':'first:flex hidden'}`}>
        <motion.img
            className={"w-full object-cover z-10"}
            src={value.url}
            ref={observe}
        />
    </div>
}

export default CarouselImg