import { useEffect } from 'react';

const useBodyScroll = (isEnabled) => {
    useEffect(() => {
        if (isEnabled) {
            document.body.style.overflow = 'auto'
        } else {
            document.body.style.overflow = 'hidden'
        }
    }, [isEnabled])
}

export default useBodyScroll

// const Example = () => {
//     const [scrollEnabled, setScrollEnabled] = useState(true)
//
//     useBodyScroll(scrollEnabled)
//
//     return (
//         <div>
//             <button onClick={() => setScrollEnabled(!scrollEnabled)}>
//                 Toggle Scroll
//             </button>
//         </div>
//     );
// }
