import React, {useContext, useEffect, useRef, useState} from 'react';
import './App.css';
import {Outlet, useNavigate,useLocation } from "react-router-dom";

import searchCtx from "./store/SearchCtx";
import LanguageIcon from "./components/icons/LanguageIcon";
import ResultSection from "./components/ResultSection/ResultSection";
import SearchIcon from "./components/icons/SearchIcon";
import {motion, AnimatePresence,AnimateSharedLayout,useAnimation } from "framer-motion";
import ShowExhibitCard from "./components/DetailSection/ShowExhibitCard";
import ShowMuseumCard from "./components/DetailSection/ShowMuseumCard";

import BusNavigation from "./components/Bus/BusNavigation";
import SearchSection from "./components/SearchSection/SearchSection";
import BusButton from "./components/Bus/BusButton";
import {popProps} from "./utils/animationType";
import {TiWarningOutline} from 'react-icons/ti'
import MenuContainer from "./components/Menu/MenuContainer";
import ErrorModal from "./components/ErrorModal";
import {useParams} from "react-router";
import {formatExhibit, formatMuseum, getOneExhibit, getOneMuseum} from "./utils/getData";
import useOverflowDetector from "./hooks/useOverflowDetector";
import About, {isOpenAboutPageAtom} from "./components/About";
import useBodyScroll from "./hooks/useBodyScroll";
import {useAtom,atom} from "jotai";
import scrollToId from "./utils/scrollToId";
import useCardControl from "./hooks/useCardControl";
import ReportIcon from "./components/icons/ReportIcon";
import LeftArrow from "./components/icons/LeftArrow";
import {cardState} from "./store/ValtioState";
import { useSnapshot } from 'valtio'

export const isOpenNavigationAtom = atom(false)

function App() {
    const { searchBarInView, toggleLanguage,
        showSearchPage, originalMuseums,originalExhibits,checkResultExhibit
    }=useContext(searchCtx)
    const {
        fadeExhibit, fadeMuseum, selectedMuseum, selectedExhibit,
    } = useSnapshot(cardState) as typeof cardState

    const [copyrightText,setCopyrightText]=useState('Copyright © 2023 - All right reserved by Panosensing Ltd.')
    const copyrightRef = useOverflowDetector(640,20,
        ()=>{setCopyrightText('Copyright © 2023 Panosensing Ltd.')}
    )
    const [isOpenNavigation,setIsOpenNavigation] = useAtom(isOpenNavigationAtom)
    const [isOpenAboutPage,setIsOpenAboutPage] = useAtom(isOpenAboutPageAtom)

    const {searchIconHandler, closeOneCard, closeAllCard} = useCardControl()

    const navigate = useNavigate()
    const {exhibitId,museumId,exhibitId2,museumId2} = useParams()

    useEffect(()=>{
        if(exhibitId){
            if (originalExhibits.length === 0) {
                setTimeout(() => {
                    getOneExhibit('', exhibitId).then(el => {
                        cardState.selectedExhibit = formatExhibit(el)
                        navigate(`/exhibits/${exhibitId}`)
                    }).catch(el => {
                        navigate('/')
                        return
                    })
                }, 500)
            } else {
                cardState.selectedExhibit = originalExhibits.find(el => el.id.toString() === exhibitId)
                // cardState.selectedExhibit = originalExhibits.find(el => el.exhibitName.toString() === exhibitId)
            }
            if(museumId2){
                if (originalMuseums.length === 0) {
                    setTimeout(() => {
                        getOneMuseum('', museumId2).then(el => {
                            cardState.fadeMuseum = formatMuseum(el)
                            navigate(`/exhibits/${exhibitId}/${museumId2}`)
                        }).catch(el => navigate(`/exhibits/${exhibitId}`))
                    }, 1000)
                } else {
                    cardState.fadeMuseum = originalMuseums.find(el => el.id.toString() === museumId2)
                    // cardState.fadeMuseum = originalMuseums.find(el => el.name.toString() === museumId2)
                }
            }else{
                cardState.fadeMuseum = undefined
            }
        }else{
            cardState.fadeMuseum = undefined
            cardState.selectedExhibit = undefined
        }

        if(museumId){
            checkResultExhibit(false)
            scrollToId(`museum${museumId}`)
            if (originalMuseums.length === 0) {
                setTimeout(() => {
                    getOneMuseum('', museumId).then(el => {
                        cardState.selectedMuseum = formatMuseum(el)
                        navigate(`/museums/${museumId}`)
                    }).catch(el => {
                        navigate('/')
                        return
                    })
                }, 500)
            } else {
                cardState.selectedMuseum = originalMuseums.find(el => el.id.toString() === museumId)
                // cardState.selectedMuseum = originalMuseums.find(el => el.name.toString() === museumId)
            }
            if(exhibitId2){
                if (originalExhibits.length === 0) {
                    setTimeout(() => {
                        getOneExhibit('', exhibitId2).then(el => {
                            cardState.fadeExhibit = formatExhibit(el)
                            navigate(`/museums/${museumId}/${exhibitId2}`)
                        }).catch(el => navigate(`/museums/${museumId}`))
                    }, 1000)
                } else {
                    cardState.fadeExhibit = originalExhibits.find(el => el.id.toString() === exhibitId2)
                    // cardState.fadeExhibit = originalExhibits.find(el => el.exhibitName.toString() === exhibitId2)
                }
            }else{
                cardState.fadeExhibit = undefined
            }
        }else{
            cardState.fadeExhibit = undefined
            cardState.selectedMuseum = undefined
        }

    },[exhibitId,exhibitId2,museumId,museumId2])

    useBodyScroll(!selectedMuseum&&!selectedExhibit&&showSearchPage&&!isOpenAboutPage&&!isOpenNavigation)



    return (
    <div className="App flex flex-col items-center overflow-x-hidden">

        {/*MainNavigation*/}
        <div className={`backdrop-blur fixed top-0 left-0 w-screen h-16 flex justify-between items-center px-6 md:px-12 ${isOpenNavigation||isOpenAboutPage?'bg-white':'bg-white/80'} transition-colors text-wine-500 z-[100]`}
             // onClick={backCard}
        >
            {/*<div className="w-full h-full bg-white/80 blur absolute"></div>*/}

            <div className={"flex items-center gap-3"}>
                <AnimatePresence>{(selectedMuseum||selectedExhibit)&&(
                    <motion.div className="text-xl hidden md:block md:text-2xl cursor-pointer hover:text-wine-300"
                                initial={{width:0}}
                                animate={{width:36}}
                                exit={{width:0}}
                                onClick={closeOneCard}
                    >
                        <LeftArrow/>
                    </motion.div>
                )}</AnimatePresence>

                <div className={"text-xl md:text-2xl font-bold  cursor-pointer pointer-events-auto select-none  whitespace-nowrap"}
                     onClick={searchIconHandler}
                >VirtualMuseum.world</div>

                <AnimatePresence>{(!searchBarInView||selectedMuseum||selectedExhibit||isOpenAboutPage)&&(
                    <motion.div
                            {...popProps}
                        className="w-8 h-8 rounded-full bg-wine-400 text-white flex justify-center items-center cursor-pointer relative z-[100]"
                        whileHover={{scale:1.2}}
                            whileTap={{scale:0.9}}
                            onClick={searchIconHandler}
                    >
                        <div className="w-4 md:w-4 -scale-x-100 flex-shrink-0" >
                            <SearchIcon/>
                        </div>
                    </motion.div>
                )}</AnimatePresence>
            </div>

            <div className="flex-1 h-full" onClick={closeOneCard}></div>
            <div className={"flex items-center justify-around gap-6 md:gap-12"}>
                {/*NavigationItem*/}
                <div className={"z-[110] w-6 h-6 cursor-pointer hover:text-wine-300"} onClick={()=>{
                    closeAllCard()
                    setIsOpenAboutPage(false)
                    toggleLanguage()
                    // navigate('/en')
                }}><LanguageIcon/></div>
                <div className={"w-[28px] h-[28px] cursor-pointer hover:text-wine-300 hidden md:block"}><a href="https://forms.gle/mo321C4Q4BdoXk2F7" target="_blank"><ReportIcon/></a></div>

                <div className={`cursor-pointer group peer ${isOpenNavigation?'open':''}`} onClick={()=>{setIsOpenNavigation(prev=>!prev)}}>
                    <div
                        className="pointer-events-none top-0 bg-wine-400 rounded-full w-[20px] h-[3px] group-open:rotate-45 transition-all group-open:top-[7px] relative group-open:bg-wine-700 group-hover:bg-wine-300"></div>
                    <div
                        className="pointer-events-none bg-wine-400 rounded-full w-[20px] h-[3px] mt-1 group-open:opacity-0 transition-all opacity-100 group-open:bg-wine-700 group-hover:bg-wine-300"></div>
                    <div
                        className="pointer-events-none top-0 bg-wine-400 rounded-full w-[20px] h-[3px] mt-1 group-open:-rotate-45 transition-all group-open:-top-[7px] relative group-open:bg-wine-700 group-hover:bg-wine-300"></div>
                </div>

            </div>
        </div>
        <MenuContainer isOpen={isOpenNavigation} closeAllCard={closeAllCard} closeHandler={()=>{setIsOpenNavigation(prev=>!prev)}}/>

        <div className={"h-1"} id="topPoint"/>
        {/*MainContent*/}
        <div className={"mt-16 flex flex-col items-center max-w-[90rem]"}>

            <SearchSection/>

            {/*<ResultSection />*/}
            <ResultSection/>

            {/*DetailSection*/}
            {!showSearchPage && < ShowMuseumCard layoutName='Bus' childLayoutName='fade' museumData={selectedMuseum}/>}
            {showSearchPage && < ShowMuseumCard layoutName='' childLayoutName='fade' museumData={selectedMuseum}/>}
            <ShowExhibitCard layoutName='fade' childLayoutName='no' exhibitData={fadeExhibit} wouldCloseSelf={true}/>

            <ShowExhibitCard layoutName='' childLayoutName='fade' exhibitData={selectedExhibit}/>
            <ShowMuseumCard layoutName='fade' childLayoutName='no' museumData={fadeMuseum} wouldCloseSelf={true}/>

            {/*<Outlet/>*/}
            <About/>

            <BusNavigation/>
            {/*<BusButton/>*/}

            {/*MenuBackdrop*/}
            <div className={`fixed w-screen h-screen top-14 left-0 bg-black/60 z-[55] transition-opacity duration-300 ${isOpenNavigation?'opacity-1':'opacity-0 pointer-events-none'}`}
                 onClick={()=>{setIsOpenNavigation(prev=>!prev)}}
            />

            {/*火車按鈕*/}
            {/*<div className={"fixed bottom-4 left-4"}>*/}
            {/*    <div className={"w-10 h-10 bg-red-300"}></div>*/}
            {/*</div>*/}

            {/*</div>*/}

        </div>

        <ErrorModal/>

        <footer className={`z-0 footer footer-center bg-wine-400 text-white fixed bottom-0 transition-opacity ${!showSearchPage&&'opacity-0'}`}>
            <div className="h-8">
                <p ref={copyrightRef}>{copyrightText}</p>
            </div>
        </footer>
    </div>
  );
}

export default App;